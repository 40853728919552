@use 'sass:math';

// ==========  TYPOGRAPHY  ========== //
$preferred_font: $font-family-sans-serif;
$performance_font: $font-family-sans-serif;

// ==========  COLORS  ========== //

// Material design color palettes.
// @see http://www.google.com/design/spec/style/color.html

@import 'color-definitions';
@import 'functions';

// ==========  Color & Themes  ========== //

$color-primary: $brand-primary !default;
$color-accent: $brand-success !default;

// ==========  TYPOGRAPHY  ========== //

$text-color-primary: unquote('rgba(#{$color-black}, 0.87)') !default;
$text-link-color: $color-primary !default;

$target-elements-directly: true !default;

// ==========  Card  ========== //

$card-background-color: unquote('rgb(#{$color-white})') !default;
$card-text-color: unquote('rgb(#{$color-black})') !default;
$card-image-placeholder-color: unquote('rgb(#{$color-accent})') !default;
$card-supporting-text-text-color: unquote(
    'rgba(#{$color-black}, 0.54)'
) !default;
$card-border-color: rgba(0, 0, 0, 0.1) !default;
$card-subtitle-color: unquote('rgba(#{$color-black}, 0.54)') !default;

// CARD //

// Card dimensions //
$card-width: 330px !default;
$card-height: 200px !default;
$card-font-size: 16px !default;
$card-title-font-size: 24px !default;
$card-subtitle-font-size: 14px !default;
$card-horizontal-padding: 16px !default;
$card-vertical-padding: 16px !default;

$card-title-perspective-origin-x: 165px !default;
$card-title-perspective-origin-y: 56px !default;

$card-title-transform-origin-x: 165px !default;
$card-title-transform-origin-y: 56px !default;

$card-title-text-transform-origin-x: 149px !default;
$card-title-text-transform-origin-y: 48px !default;

$card-supporting-text-font-size: 14px !default;
$card-supporting-text-line-height: 18px !default;

$card-actions-font-size: 16px !default;

$card-title-text-font-weight: 300 !default;
$card-z-index: 1 !default;

// Cover image //
$card-cover-image-height: 186px !default;
$card-background-image-url: '' !default;

// BADGE //
$badge-font-size: 12px !default;
$badge-color: #333 !default;
$badge-color-inverse: $brand-info !default;
$badge-background: $brand-info !default;
$badge-background-inverse: #999 !default;
$badge-size: 22px !default;
$badge-padding: 2px !default;
$badge-overlap: 12px !default;

// SHADOWS //

$shadow-key-umbra-opacity: 0.2 !default;
$shadow-key-penumbra-opacity: 0.14 !default;
$shadow-ambient-shadow-opacity: 0.12 !default;

// ANIMATION //
$animation-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1) !default;
$animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1) !default;
$animation-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1) !default;

$animation-curve-default: $animation-curve-fast-out-slow-in !default;

// DATA TABLE //

$data-table-font-size: 13px !default;
$data-table-header-font-size: 12px !default;
$data-table-header-sort-icon-size: 16px !default;

$data-table-header-color: rgba(#000, 0.54) !default;
$data-table-header-sorted-color: rgba(#000, 0.87) !default;
$data-table-header-sorted-icon-hover-color: rgba(#000, 0.26) !default;
$data-table-divider-color: rgba(#000, 0.1) !default;

$data-table-hover-color: rgba(0, 0, 0, 0.08) !default;
$data-table-selection-color: #e0e0e0 !default;

$data-table-dividers: 1px solid $data-table-divider-color !default;

$data-table-row-height: 48px !default;
$data-table-last-row-height: 56px !default;
$data-table-header-height: 56px !default;

$data-table-column-spacing: 36px !default;
$data-table-column-padding: math.div($data-table-column-spacing, 2);

$data-table-card-header-height: 64px !default;
$data-table-card-title-top: 20px !default;
$data-table-card-padding: 24px !default;
$data-table-button-padding-right: 16px !default;
$data-table-cell-top: math.div($data-table-card-padding, 2);

// ==========  DARK THEME  ========== //
$theme-dark-data-table-header-color: rgba(#fff, 0.5);
$theme-dark-data-table-content-color: rgba(#fff, 0.7);
