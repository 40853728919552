@use '@angular/material' as mat;

@import '@angular/material/theming';

/* For use in src/lib/core/theming/_palette.scss */
$solocal-primary-palette: (
    50: #e0effe,
    100: #b3d7fe,
    200: #80bcfd,
    300: #4da1fc,
    400: #268cfb,
    500: #0078fa,
    600: #0070f9,
    700: #0065f9,
    800: #005bf8,
    900: #0048f6,
    A100: #fff,
    A200: #eaefff,
    A400: #b7c7ff,
    A700: #9db4ff,
    contrast: (
        50: #000,
        100: #000,
        200: #000,
        300: #000,
        400: #fff,
        500: #fff,
        600: #fff,
        700: #fff,
        800: #fff,
        900: #fff,
        A100: #000,
        A200: #000,
        A400: #000,
        A700: #000,
    ),
);

$solocal-app-primary: mat.define-palette($solocal-primary-palette);
$solocal-app-accent: mat.define-palette($mat-green);
$solocal-app-theme: mat.define-light-theme(
    (
        color: (
            primary: $solocal-app-primary,
            accent: $solocal-app-accent,
        ),
    )
);

.solocal-colors {
    @include angular-material-color($solocal-app-theme);
}
