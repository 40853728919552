.z-depth-1 {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
        0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.z-depth-1-half {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
        0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.z-depth-2 {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.z-depth-3 {
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
        0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.z-depth-4 {
    box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22),
        0 25px 55px 0 rgba(0, 0, 0, 0.21);
}
.z-depth-5 {
    box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
        0 40px 77px 0 rgba(0, 0, 0, 0.22);
}
#loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 99999;
    background-size: 100px 100px;
}

#loader-container > .loading-banner {
    width: 100px;
    height: 100px;

    animation: loading-banner-turn 1s linear infinite;
}

#loader-container > .loading-banner > .loading-banner__item {
    animation: loading-banner-stroke 6s linear infinite;
    fill: none;
    stroke-dasharray: 50;
    stroke-dashoffset: 50;
    stroke-linecap: round;
    stroke-width: 4;
}

#loader-container > .loading-banner > .loading-banner__item:nth-child(1) {
    stroke: #0078fa;
}

#loader-container > .loading-banner > .loading-banner__item:nth-child(2) {
    stroke: #19D77D;
    animation-delay: 1s;
}

#loader-container > .loading-banner > .loading-banner__item:nth-child(3) {
    stroke: #cd05ff;
    animation-delay: 2s;
}

#loader-container > .loading-banner > .loading-banner__item:nth-child(4) {
    stroke: #501eff;
    animation-delay: 3s;
}

#loader-container > .loading-banner > .loading-banner__item:nth-child(5) {
    stroke: #0078FA;
    animation-delay: 4s;
}

#loader-container > .loading-banner > .loading-banner__item:nth-child(6) {
    stroke: #FFAF14;
    animation-delay: 5s;
}

@keyframes loading-banner-turn {
    50% {
        transform: rotate(180deg)
    }
    100% {
        transform: rotate(720deg)
    }
}

@keyframes loading-banner-stroke {
    8.3333333333% {
        stroke-dashoffset: 0;
    }
    16.6666666667%, 100% {
        stroke-dashoffset: 63;
    }
}
