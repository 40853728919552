@import "node_modules/@shared/ui-components/lib/styles/mixins";
@import "breakpoints-deprecated";

@mixin ie-10-11 {

    @media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
        @content;
    }
}

@mixin ms-edge {
    @supports (-ms-ime-align: auto) {
        // + 'and (-webkit-text-stroke: initial)' for newer ver
        @content;
    }
}

@mixin moz-ff {
    @supports (-moz-appearance: meterbar) {
        @content;
    }
}

@mixin moz-ff-on-mac {

    /* Firefox 25 or newer on mac */
    @supports (-moz-osx-font-smoothing: auto) {
        @content;
    }
}

@mixin chrome {
    @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
        @content;
    }
}

@mixin lanscape {
    @media (orientation: landscape) {
        @content;
    }
}

@mixin lanscape-small-device {
    @media (orientation: landscape) and (max-width: 1024px) {
        @content;
    }
}
