:focus {
    outline: none !important;
}

// default-contrast of Accent and Warm should be white text instead of dark, so manually overrides
button,
a {
    &[md-fab],
    &[md-mini-fab],
    &[md-raised-button] {
        text-decoration: none;
    }

    &[md-raised-button].mat-warn,
    &[md-fab].mat-warn,
    &[md-mini-fab].mat-warn,
    &[md-raised-button].mat-accent,
    &[md-fab].mat-accent,
    &[md-mini-fab].mat-accent {
        color: $white;
    }
    &[md-fab],
    &[md-mini-fab] {
        color: $white;
    }

    // &.btn-lg {
    //   // line-height: ensure even-numbered height of button next to large input
    //   @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-border-radius-lg);
    // }
    // &.btn-sm {
    //   // line-height: ensure proper height of button next to small input
    //   @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $btn-border-radius-sm);
    // }
}

.mat-mdc-raised-button.mat-primary,
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
    background-color: #0078fa;
}

.mat-mdc-raised-button.mat-primary[disabled],
.mat-raised-button.mat-accent[disabled],
.mat-raised-button.mat-warn[disabled],
.mat-raised-button[disabled][disabled],
.mat-mdc-fab.mat-primary[disabled],
.mat-fab.mat-accent[disabled],
.mat-fab.mat-warn[disabled],
.mat-fab[disabled][disabled],
.mat-mdc-mini-fab.mat-primary[disabled],
.mat-mini-fab.mat-accent[disabled],
.mat-mini-fab.mat-warn[disabled],
.mat-mini-fab[disabled][disabled] {
    background-color: rgba(32, 94, 217, 0.4) !important;
    color: rgba(255, 255, 255, 0.6) !important;
    cursor: not-allowed;
}

// header dropdown
.md-menu-content {
    a[md-menu-item] {
        text-decoration: none;
        &:hover,
        &:focus {
            color: $body-color;
        }
    }
}

[md-tab-nav-bar],
.md-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

[mdInput] {
    width: 100%;
}

md-input-container {
    &.md-icon-left {
        position: relative;
        padding-left: 36px;
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-wrapper {
            position: relative;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-infix > md-icon {
            position: absolute;
            top: 0;
            left: -32px;
            right: auto;
        }
    }
}
.mat-mdc-form-field .mat-icon.material-icons {
    font-size: 24px;
}

.md-radio-group-spacing {
    md-radio-button {
        margin-right: 20px;
        &:last-child {
            margin-right: 0;
        }
    }
}

// https://stackoverflow.com/questions/47782890/angular-material-dialog-component-hides-all-my-website-components
.cdk-global-scrollblock {
    position: initial;
}

// manage responsive cases for new-header > mat panel (todolist / faq...)
@include custom-width-900 {
    .mat-mdc-menu-panel {
        max-width: 100% !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .cdk-overlay-pane:has(:not(.slm-mat-select-location)) {
        right: auto;
    }

    .custom-menu-dropdown {
        max-width: 100% !important;
    }
}
