.page-with-tabs {
    > .page-title {
        background-color: rgba(#000, 0.05);
        padding: 30px 15px 30px 39px;

        h2 {
            margin: 0;
            font-size: 20px;
            line-height: 1;
            font-weight: $headings-font-weight;
            color: $brand-primary;
        }
    }

    > md-tab-group {
        > md-tab-header {
            background-color: rgba(#000, 0.05);
            border-bottom: 1px solid rgba(#000, 0.05);
        }
    }
}
