/**
    Don't use this breakpoints anymore.

    In breakpoints.scss you find new ones based on build ui
 */


@mixin small-width {
    @warn "this mixin is deprecated, please use the media mixin from @shared/ui-components instead";

    @media only screen and (max-width: 480px) {
        @content;
    }
}

@mixin custom-width-800 {
    @warn "this mixin is deprecated, please use the media mixin from @shared/ui-components instead";

    @media only screen and (max-width: 800px) {
        @content;
    }
}

@mixin large-width {
    @warn "this mixin is deprecated, please use the media mixin from @shared/ui-components instead";

    @media only screen and (max-width: 1023px) {
        @content;
    }
}

@mixin x-large-width {
    @warn "this mixin is deprecated, please use the media mixin from @shared/ui-components instead";

    @media only screen and (max-width: 1280px) {
        @content;
    }
}

@mixin custom-width-400 {
    @warn "this mixin is deprecated, please use the media mixin from @shared/ui-components instead";

    @media only screen and (max-width: 400px) {
        @content;
    }
}

@mixin custom-width-540 {
    @warn "this mixin is deprecated, please use the media mixin from @shared/ui-components instead";

    @media only screen and (max-width: 540px) {
        @content;
    }
}

@mixin custom-width-599 {
    @warn "this mixin is deprecated, please use the media mixin from @shared/ui-components instead";

    @media only screen and (max-width: 599px) {
        @content;
    }
}

@mixin custom-width-768 {
    @warn "this mixin is deprecated, please use the media mixin from @shared/ui-components instead";

    @media only screen and (max-width: 768px) {
        @content;
    }
}

@mixin custom-min-width-768 {
    @warn "this mixin is deprecated, please use the media mixin from @shared/ui-components instead";

    @media only screen and (min-width: 768px) {
        @content;
    }
}

@mixin custom-width-900 {
    @warn "this mixin is deprecated, please use the media mixin from @shared/ui-components instead";

    @media only screen and (max-width: 900px) {
        @content;
    }
}

@mixin custom-width-991 {
    @warn "this mixin is deprecated, please use the media mixin from @shared/ui-components instead";

    @media only screen and (max-width: 991px) {
        @content;
    }
}

@mixin custom-width-1080 {
    @warn "this mixin is deprecated, please use the media mixin from @shared/ui-components instead";

    @media only screen and (max-width: 1080px) {
        @content;
    }
}

@mixin custom-width-1180 {
    @warn "this mixin is deprecated, please use the media mixin from @shared/ui-components instead";

    @media only screen and (max-width: 1180px) {
        @content;
    }
}

@mixin custom-min-width-1180 {
    @warn "this mixin is deprecated, please use the media mixin from @shared/ui-components instead";

    @media only screen and (min-width: 1180px) {
        @content;
    }
}

@mixin custom-width-1237 {
    @warn "this mixin is deprecated, please use the media mixin from @shared/ui-components instead";

    @media only screen and (max-width: 1237px) {
        @content;
    }
}

@mixin custom-width-1360 {
    @warn "this mixin is deprecated, please use the media mixin from @shared/ui-components instead";

    @media only screen and (max-width: 1360px) {
        @content;
    }
}

@mixin custom-width-1415 {
    @warn "this mixin is deprecated, please use the media mixin from @shared/ui-components instead";

    @media only screen and (max-width: 1415px) {
        @content;
    }
}

@mixin custom-width-1500 {
    @warn "this mixin is deprecated, please use the media mixin from @shared/ui-components instead";

    @media only screen and (max-width: 1500px) {
        @content;
    }
}

@mixin custom-width-1600 {
    @warn "this mixin is deprecated, please use the media mixin from @shared/ui-components instead";

    @media only screen and (max-width: 1600px) {
        @content;
    }
}

@mixin custom-width($width) {
    @warn "this mixin is deprecated, please use the media mixin from @shared/ui-components instead";

    @media only screen and (max-width: $width + px) {
        @content;
    }
}

@mixin custom-height($height) {
    @warn "this mixin is deprecated, please use the media mixin from @shared/ui-components instead";

    @media only screen and (max-height: $height + px) {
        @content;
    }
}

@mixin small-height {
    @warn "this mixin is deprecated, please use the media mixin from @shared/ui-components instead";

    @media only screen and (max-height: 768px) {
        @content;
    }
}

@mixin extra-small-height {
    @warn "this mixin is deprecated, please use the media mixin from @shared/ui-components instead";

    @media only screen and (max-height: 640px) {
        @content;
    }
}
