@import 'variables';
@import 'mixins';
@import 'functions';

.slm-table-row {
    &.custom-fields {
        display: flex;
        align-items: center;

        p {
            margin: 0;
        }

        .mat-mdc-cell,
        .mat-mdc-header-cell {
            &:first-child {
                flex: 0 0 8%;
            }
        }

        &.cdk-drag-preview {
            box-sizing: border-box;
            border-radius: 4px;
            background-color: $my-client-progress-buffer-bg;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
                0 3px 14px 2px rgba(0, 0, 0, 0.12);
        }

        &.cdk-drag-placeholder {
            opacity: 0;
        }

        &.cdk-drag-animating {
            transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
        }

        &.cdk-drop-list-dragging &:not(.cdk-drag-placeholder) {
            transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
        }

        .custom-field-name {
            white-space: normal;
            font-size: rem(16, 16);
            font-weight: 500;
        }

        .action-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__edit {
                display: block;
                margin-right: rem(32, 16);

                @include custom-width-768 {
                    margin-right: rem(16);
                }
            }
        }

        .mat-column-customFieldName {
            @include custom-width-768 {
                flex: 0 0 45%;
            }
        }

        .mat-column-customFieldActions {
            @include custom-width-768 {
                padding-left: 0;
                padding-right: rem(32);
                border: 0;
            }
        }

        .custom-field-type,
        .custom-field-mandatory {
            font-size: rem(15, 16);
            font-size: 400;
            color: $my-client-grey-darker;

            @include custom-width-768 {
                display: none;
            }
        }
    }
}
