@media print {
    #inbenta-bot-sdk,
    slm-bottom-nav {
        display: none;
    }

    snack-bar-container,
    mat-sidenav,
    snack-bar-frame {
        display: none !important;
    }

    mat-sidenav-content {
        margin-left: 0 !important;
    }
}
