@import 'variables';
@import 'mixins';

.nav-fixed {
    display: block;
    min-height: 5vh;
    top: 5rem;
    z-index: 1;
    padding: 0 2rem;

    @include small-width() {
        padding: 0 0.5rem;
    }
}

* {
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background: $black-alpha-04;
        overflow: hidden;
    }

    &::-webkit-scrollbar-thumb {
        height: 12px;
        width: 6px;
        border-radius: 6px;
        background: $grey-color;
    }
}

.show-scrollbar-on-hover {
    &:not(:hover) {
        scrollbar-width: none;
        margin-right: 6px !important;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

// Move header down when app store banner is displayed
html[data-smartbanner-original-margin-top] {
    .root-redirect-container,
    #slm-side-nav-container,
    .account-popover,
    .todolist-container {
        margin-top: 84px !important;
    }

    slm-header-navbar {
        top: 84px !important;

        .header-bar__menu.open {
            top: calc(5rem + 84px) !important;

            @include custom-width-768 {
                top: calc(8.75rem + 84px) !important;
            }
            @include custom-width-599 {
                top: calc(7.25rem + 84px) !important;
            }
        }
    }
}
