@import '../../../../../../assets/variables';

// Basic values
$main-color: #0078fa;
$main-color-2: #0078fa;
$primary-blue: #0a68f1;

$base-color: $main-color-2;
$second-color: #eef0f5;

$main-grad-start: #006eff;
$main-grad-stop: #00d5ff;

$title-color: $base-color;
$label-background-color: $base-color;
$link-color: $base-color;

// Plain colors
$white: #fff;
$black: #000;
$red: #f00;

$grey-color: #959ba6;
$black-color: #212226;
$purple-color: #cd05ff;

$light-grey-text: #b9b8b8;

// design frame colors
$grey-border: #d2d2dc;
$box-shadow: 0px 5px 10px #0000001a;

// SIDE AND HEADER MENU DESIGN
$header-menu-height: 3.75rem;
$sidenav-small-width: 4.375rem; // 70px
$sidenav-large-width: 17.5rem; // 280px
$breadcrumbs-height: 8.2rem;
$breadcrumbs-height-small: 5rem;
$inner-nav-height: 3rem;
$loc-selector-height: 2rem;

// New Todo List variables.
$todo-left-padding: 1.7rem;
$todo-right-padding: 1.2rem;
$todo-group-content-max-height: 15rem;
$todo-item-height: 6rem;
$todo-list-done: #a8a8a8;
$todo-list-done-bg: #f5f5fa;

//CRM MODULE VARIABLES
$tooltip-icon-width: 40px;

// Common alphas
$transparent: transparent;
$main-alpha-66: rgba(0, 74, 159, 0.66);
$black-alpha-00: rgba(0, 0, 0, 0);
$black-alpha-02: rgba(0, 0, 0, 0.02);
$black-alpha-04: rgba(0, 0, 0, 0.04);
$black-alpha-10: rgba(0, 0, 0, 0.1);
$black-alpha-11: rgba(0, 0, 0, 0.11);
$black-alpha-15: rgba(0, 0, 0, 0.15);
$black-alpha-20: rgba(0, 0, 0, 0.2);
$black-alpha-30: rgba(0, 0, 0, 0.3);
$black-alpha-54: rgba(0, 0, 0, 0.54);
$black-alpha-60: rgba(0, 0, 0, 0.6);
$black-alpha-65: rgba(0, 0, 0, 0.65);
$black-alpha-75: rgba(0, 0, 0, 0.75);
$black-alpha-80: rgba(0, 0, 0, 0.8);
$black-alpha-87: rgba(0, 0, 0, 0.87);
$black-alpha-100: rgba(0, 0, 0, 1);
$white-alpha-00: rgba(255, 255, 255, 0);
$white-alpha-15: rgba(255, 255, 255, 0.15);
$white-alpha-20: rgba(255, 255, 255, 0.2);
$white-alpha-50: rgba(255, 255, 255, 0.5);
$white-alpha-75: rgba(255, 255, 255, 0.75);
$white-alpha-100: rgba(255, 255, 255, 1);
$grey-alpha-30: rgba(155, 155, 155, 0.3);
$grey-alpha-05: rgba(155, 155, 155, 0.05);
$blue-alpha-10: rgba(32, 94, 217, 0.1);
$blue-alpha-25: rgba(66, 134, 244, 0.25);
$blue-alpha-26: rgba(80, 30, 255, 0.26);
$green-alpha-26: rgba(82, 215, 125, 0.26);
$tooltip-bg: rgba(255, 255, 255, 0.86);
$screen-shade: rgba(0, 0, 0, 0.7);
$del-btn-hover: rgba(255, 0, 0, 0.5);
$radio-ripple: rgba(32, 94, 217, 0.25);
$check-ripple: rgba(27, 142, 241, 0.15);

// Statuses
$status-pending: #9b9b9b;
$status-not_connected: #ebaa00;
$status-active: #56c224;
$status-rejected: #f00;
$status-error: #f00;
$error: #f00;
$success: #56c224;
$info: $main-color;
$sending: #ebaa00;
$sent: #56c224;
$closed: #b7b9bf;
$invalid: #f00;
$chart-falls: #f00;
$chart-grows: #56c224;
$chart-flat: $black;

$font-stack: Helvetica, sans-serif;
$font-size-base: 14px;
$image-path: '/assets/images' !default;

$publishers-all: 'gmb' 'instagram' 'pagesjaunes' 'googlemaps' 'facebook' 'bing' 'apple' 'yahoo' 'foursquare' 'petitfute' '118000'
    '123pages' 'waze_navads' 'here' 'tomtom' 'navmii' 'seety' 'pharmanity' 'site_privilege' 'factual_navads' 'soms'
    'here_navads' 'garmin' 'bingplaces' 'navads' 'uber_navads' 'twitter' 'linkedin' 'pinterest' 'tripadvisor' 'ooreka'
    'qwant' 'apple_navads' 'justacote_navads' 'pages24_navads' 'horaire_navads' 'annuaire_navads' 'bing_navads'
    'trouverouvert_navads' 'horairesdouverture24_navads' 'linkedin2' 'twitter2';
$statuses: 'rejected' 'pending' 'not_connected' 'active' 'error' 'do_not_connect' 'deleted' 'duplicate';
$states: 'valided' 'sent' 'opened' 'clicked' 'reviewed' 'published';

// Login Page
$log-bg: $white;
$log-label: #828182;
$log-forgot-link: #0078fa;
$log-input: #212226;
$log-input-icon: #ddd;
$log-input-border: #d2d2dc;
$log-invalid: $invalid;
$log-signup: #4c4c4c;
$log-signup-btn: #2b5eda;
$pass-res: #4c4c4c;
$pass-res-error: $error;
$pass-res-valid-btn: #2b5eda;
$pass-res-cancel-btn: #0078fa;
$pass-res-valid-dial-msg: #d8dbe1;
$pass-res-valid-dial-msg-border: #959ba6;
$pass-res-valid-dial-btn-add: #2b5eda;
$pass-res-valid-dial-btn-hist: #0078fa;

// Choose company Page
$auth-bg: $white;
$auth-label: #828182;
$auth-select-icon: #ddd;
$auth-select-border: #d3d3d3;
$auth-select-border-hover: #c3c3c3;

// Home Page
$compl-widget-bg: $white;
$post-widget-disabled-btn: #808080;
$post-widget-new-btn-title: $white;
$post-widget-sep: $black-alpha-10;
$rev-widget-bg: $white;
$rev-widget-nav: #212226;
$rev-widget-avg-content: $white;
$rev-widget-stars-bar: #ffec00;
$rev-widget-quick-link: $white;
$rev-widget-quick-link-bg: #2b5eda;
$rev-widget-quick-link-title: $white;
$stat-widget-disabled-bg: $white-alpha-15;
$stat-widget-amount: $base-color;
$sugg-widget-icon: #e1eaf7;
$stat-widget-pub-header-bg: #fafafa;

// Post Page
$posts-list-bg: #fff;
$new-post-header-bg: $base-color;
$new-post-btn-txt: #b6b9bf;
$new-post-btn-txt-2: #b4b8bd;
$new-post-option-text: #0063d1;
$new-post-active-btn-bg: #0078fa;
$new-post-active-btn-txt: white;
$new-post-label: #b4b8bd;
$new-post-focused: #0063d1;
$new-post-invalid: #ef5350;
$new-post-dropbox-bg: #ecf0f4;
$new-post-picgrid-overlay: #9c9375;
$new-post-picgrid-overlay-edge: $black;
$new-post-action-icon: #0078fa;
$new-post-action-icon-hover: #0e2855;

$post-list-bg: #fff;
$post-list-item-loc-title: $black-alpha-80;
$post-list-item-pic-grid-overlay: $white;
$post-list-item-pub-locs-bg: #f2f4f6;
$post-list-item-pub-error: $error;
$post-list-item-pub-stat-num: #797979;
$post-preview-close: $white;
$post-preview-close-hover-bg: $white-alpha-20;

// Reviews Page
$rep-mob-filter-bg: #fafafa;
$rep-tabs-bg: #fff;
$rep-blue: $base-color;
$rep-text: #959ba6;
$rev-comm-bg: #eef0f5;
$rev-comm-border: #d8dbe1;
$rev-comm-edit: $black;
$rev-comm-del: #e92101;
$rev-dial-bg: #eef0f5;
$rev-dial-reply: $white;
$rev-dial-reply-bg: #0078fa;
$rev-dial-msg-border: #d8dbe1;
$rev-dial-comm-cancel: #808080;
$rev-dial-comm-send: $white;
$rev-dial-comm-send-bg: #0078fa;
$rev-dial-comm-disabled: #b4b8bd;

$rev-list-item-bg: $white;
$rev-list-item-border: $grey-border;
$rev-list-item-sep-top: $black-alpha-10;
$rev-list-item-not-read: #0078fa;
$rev-list-item-head: #202226;
$rev-list-item-icon: #2a5ed9;
$rev-list-item-subgrades: #f9fafc;
$rev-list-item-footer: #202226;
$rev-list-item-response: #2a5ed9;

$rev-list-bg: #fff;
$rev-list-info: #202226;
$rev-list-btn-bg: $white;
$rev-list-btn: #0078fa;

$rev-how-to: $white;
$rev-how-to-mob: #0078fa;

// Requests
$req-form-head-bg: #2b5eda;
$req-form-head-title: $white;
$req-form-exp-title: #636467;
$req-form-exp-icon: #2b5eda;
$req-form-cancel-btn: #636467;
$req-form-cancel-send: #2b5eda;
$req-lst-th: #959ba6;
$req-lst-body-bg: $white;
$req-lst-body-action: #007bff;

// Requests Redesign
$req-form-2-head-bg: $white;
$req-form-2-head-title: #18191a;
$req-form-2-category-title: #636467;
$req-form-2-location-border: #d0d3d9;
$req-form-2-location-border-field: #0078fa ;
$req-form-2-location-option-text:#18191a;
$req-form-2-location-shadow:#18191a1a;
$req-form-2-category-data: #212226;
$req-form-2-exp-title: #2b5eda;
$req-form-2-show-more-background: #0078fa1a;
$req-form-2-exp-icon: #636467;
$req-form-2-cancel-btn: #2b5eda;
$req-form-2-cancel-send: #636467;

// Locations Page
$loc-item-border: #d2d2dc;
$loc-item-title: #212226;
$loc-item-content: #97979a;
$loc-item-progress: #55dd93;
$loc-item-border-2: #707070;

// Location Detail Page
$edit-icon: $base-color;
$cards-text: #959ba6;
$loc-disp-address-title: #212226;
$loc-disp-address-content: #959ba6;
$loc-disp-address-content-hover: #0078fa;
$loc-disp-compl-bg: $white;
$loc-disp-compl-separator: #e9ebf0;
$loc-disp-compl-sugg-border: #f3f5f7;
$loc-disp-compl-sugg-bg: #fbfbfc;
$loc-disp-compl-sugg-content: #0078fa;
$loc-disp-compl-sugg-icon: #e1eaf7;
$loc-disp-compl-progress: #00d585;
$loc-disp-compl-progress-text: #959ba6;
$loc-disp-compl-progress-bg: $white;
$loc-disp-compl-progress-border: #fafafa;
$loc-disp-compl-title: $black;
$loc-disp-compl-address: #929aa4;
$loc-disp-compl-nav-bg: #797a7c;
$loc-disp-compl-nav-active: #3578fa;
$loc-disp-compl-nav-hover-bg: $white;
$loc-disp-compl-nav-hover: #0078fa;
$loc-disp-compl-nav-dot: #a9a9a9;
$loc-disp-compl-nav-dot-bg: $white;
$loc-disp-compl-nav-dot-active: #0078fa;
$loc-disp-phone: #959ba6;
$loc-disp-media-add: #0078fa;
$loc-disp-media-border: #e9ebf0;
$loc-disp-media-border-2: $grey-alpha-30;
$loc-disp-media-overlay: $white;
$loc-disp-media-plus-nr: $white;
$loc-disp-media-plus-overlay: $black-alpha-60;
$loc-disp-pub-list-bg: $white;
$loc-disp-pub-list-bg-hover: #f8fafd;
$loc-disp-pub-list-divider: #e9ebf0;
$loc-disp-service-title: #212226;
$loc-disp-service-content: #959ba6;
$loc-disp-service-content-hover: #0078fa;
$loc-edit-title: #636467;
$loc-edit-sep: $black-alpha-10;
$loc-edit-checkbox: #959ba6;
$loc-edit-checked: #0078fa;
$loc-edit-text: #4c4c4c;
$loc-edit-help-text: #9b9b9b;
$loc-edit-section-bg: #fafafa;
$loc-edit-label: #828182;
$loc-edit-input-border: #d2d2dc;
$loc-edit-input-error: #ef5350;

// Gallery Page
$gal-img-bg: $white-alpha-50;
$gal-load-overlay: $black-alpha-30;

// Statistics Page
$stats-mob-filter-bg: rgb(250, 250, 250);
$stats-bg: #fff;
$stats-back-to: #747474;
$stats-back-to-hover: #9a9a9a;
$stats-insight-amount: #19e682;
$stats-insight-nodata: #828282;
$stats-insight-amount-border: #979797;
$stats-insight-graph-btn: $white;
$stats-insight-mentions: #2a5ed9;
$stats-graph-info-text: #808080;
$stats-graph-btn-bg: #2a5ed9;
$stats-graph-legend-1: #f5a533;
$stats-graph-legend-2: #52d77d;
$stats-graph-keyword: #808080;
$stats-tooltip: $black-color;
$stats-global-border: #979797;
$stats-fb-mentions-line: $base-color;
$stats-period-filter-border: #d3d3d3;
$stats-period-filter-border-hover: #c3c3c3;
$stats-table-rank-bg: #ffaf14;
$stats-keyword-platinium: #fef6d5;
$stats-keyword-gold: #fce78e;
$stats-keyword-silver: #ebebeb;
$stats-orange-grad-start: #ffcd32;
$stats-orange-grad-end: #ffa532;
$stats-green-grad-start: #19e682;
$stats-green-grad-end: #19c87d;

// Onboarding Page
$onboard-text: #959ba6;
$onboard-blue: $base-color;
$onboard-btn-bg: #fafafa;

// Add CRM Page
$crm-bg: #fafafa;
$input-placeholder: #959ba6;
$crm-label: #959ba6;
$crm-title: #959ba6;
$crm-hr: #ccc;
$crm-btn-txt: #b6b9bf;
$crm-blue: $base-color;
$crm-blue-2: #0078fa;
$crm-msg-border: #d8dbe1;

// Header
$head-mob-menu: #0078fa;
$head-mob-menu-bg: $white;
$head-mob-menu-underline: $transparent;
$head-mob-manu-bg: $white;
$head-menu-bg: $white;
$head-menu-title: $black;
$head-menu-active: #0078fa;
$head-menu-underline: transparent;
$head-menu-active-underline: #0078fa;
$head-menu-underline-hover: #b2c3e4;
$head-user-menu-bg: $white;
$head-user-menu-text: #6f6f6f;
$head-user-menu-hover: #0078fa;
$head-user-menu-logout: #0078fa;
$head-user-menu-underline: #0078fa;
$head-user-menu-border: $black-alpha-10;
$head-under-separator: transparent;
$head-button-border-color: #828182;

// Menu
$menu-burger: $white;
$menu-sidebar-text: $white;
$menu-sidebar-bg: $base-color;

// Body
$body-bg: #fff;

// Filters
$mob-filter-header-txt: $white;
$mob-filter-header-bg: $base-color;
$mob-filter-title: #202226;

$rev-filter-avg-rating-bg: $white;
$rev-filter-title: #959ba6;
$rev-filter-text: #202226;
$rev-filter-border: #d8dbe1;
$rev-filter-selected: $white;
$rev-filter-selected-bg: #0078fa;
$rev-filter-radio-selected: #0078fa;
$rev-filter-radio-border: $black-alpha-54;
$rev-filter-check-checked: #2196f3;
$rev-filter-stars-bar: #0078fa;

$post-filter-bg: #fff;
$post-filter-subhead: $black-alpha-80;
$post-filter-clear: #959ba6;
$post-filter-clear-hover: #0078fa;
$post-filter-clear-hover-bg: $white;
$post-filter-check-border: $black-alpha-20;
$post-filter-check-border-hover: $black-alpha-04;
$post-filter-check-checked: #0078fa;
$post-filter-loc-nr: #959ba6;

$stats-filter-border: #d8dbe1;
$stats-filter-text: #202226;
$stats-filter-amount: #959ba6;
$stats-filter-check-border: $black-alpha-20;
$stats-filter-check-border-hover: $black-alpha-04;

// Custom elements
$custom-select-bg: $base-color;
$custom-select-text: $white;
$datepicker-input-bg: $white;
$custom-checkbox-bg: $white;
$custom-checkbox-border: $base-color;
$custom-checkbox-checked: $base-color;
$custom-checkbox-tick: $base-color;
$custom-checkbox-tick-hover: #4a4a4a;
$custom-checkbox-label: #4a4a4a;
$custom-radio-circle: $base-color;
$custom-toggle-bg: $white;
$custom-toggle-border: #808080;
$custom-toggle-checked: #3a7fe6;
$custom-chart-check-search: #501eff;
$custom-chart-check-search-ripple: $blue-alpha-26;
$custom-chart-check-maps: #52d77d;
$custom-chart-check-maps-ripple: $green-alpha-26;
$custom-stats-filter-checked: $base-color;
$custom-stats-filter-ripple: $blue-alpha-25;
$custom-mat-list-bg: $white;
$custom-separator: #d8dbe1;
$custom-chip: $white;
$custom-chip-bg: $base-color;

// Map
$map-name: #1277cc;
$map-action-btn-bg: #23d8a4;
$map-pubs-info-border: #e5e5e5;

// Pagination
$pagination-color: $base-color;
$pagination-active-border-color: $base-color;
$pagination-active-bg: $base-color;
$pagination-active-color: $white;
$pagintaion-holder-bg: #f0f0f0;
$pagination-holder-border: #e7e7e7;

// Labels
$label-color: $white;
$label-warning: #d22424;
$label-active: #2a5ed9;
$label-hint: #b4b8bd;

// Prospect Publishers
$pros-pubs-bg: $white;
$pros-pubs-border: #979797;
$pros-pubs-title: $black;
$pros-pubs-text: #707070;
$pros-widg-text: $white;
$pros-widg-overlay: $black-alpha-30;

// Publisher Connect
$pubs-conn-icon-conn: $label-active;
$pubs-conn-icon-delete: $label-warning;
$pubs-conn-border: #d3d3d3;
$pubs-conn-expire: #808080;

// Connected media
$conn-media-bg: $white;
$conn-media-border: $grey-border;

// RDV Details
$rdv-border: #979797;
$rdv-text: #707070;
$rdv-subject: #0078fa;
$rdv-caro-hold-bg: $white;
$rdv-caro-hold-nav: $black;
$rdv-caro-hold-nav-hover-bg: $white;
$rdv-caro-hold-nav-hover: #0078fa;
$rdv-caro-hold-dot: #a9a9a9;
$rdv-caro-hold-dot-bg: $white;
$rdv-caro-hold-dot-bg-2: #797a7c;
$rdv-caro-active-dot: #3578fa;
$rdv-caro-hold-dot-border: #0078fa;
$rdv-caro-hold-arrow-bg: #797a7c;

// Cases
$case-text: #959ba6;
$case-blue: #0078fa;
$case-msg-border: #d8dbe1;
$case-btn: $base-color;

// Dialog
$dialog-header-bg: $base-color;
$dialog-text: #636467;
$dialog-border: #dcdcdc;
$dialog-icon: $base-color;
$dialog-cancel: #a5a5a5;
$dialog-confirm: $base-color;
$feedback-sending: $sending;
$feedback-sent: $sent;
$dialog-common-close: $white;
$dialog-common-close-ie: $white;
$dialog-help-icon: #959ba6;
$tooltip-text: #ebaa00;

// Image Dialogs
$img-dialog-overlay-bg: $black-alpha-80;
$img-dialog-arrow-bg: $black-alpha-30;
$img-dialog-icon: $white;
$img-dialog-selected-thumb: $white;
$img-dialog-controllable-img-bg: $black;
$img-gallery-dialog-bg: $black-alpha-75;
$img-gallery-dialog-icon: $white;
$img-gallery-dialog-cp: $white;
$img-gallery-dialog-image-bg: $black;
$img-list-scroll-hide: $white;
$img-list-selected: #0078fa;
$img-list-selected-tick: #9b9b9b;
$img-list-border: $grey-alpha-30;

// Inputs
$category-chip: #0078fa;
$input-add: $base-color;
$input-edit: $base-color;
$input-clear: $base-color;
$input-remove: $red;
$input-text: #9b9b9b;
$input-label: #959ba6;
$input-placeholder: #959ba6;
$input-warning: $label-warning;
$input-dropbox: #808080;
$input-toggle-bg: $white;
$input-toggle-border: #808080;
$input-toggle-checked: #3a7fe6;
$input-toggle-disabled: #959ba6;
$input-radio-checked: $base-color;
$input-check-checked: #0063d1;
$input-select-all: #0063d1;
$input-deselect: #ef5350;
$input-option-text: #0063d1;
$input-option-selected-bg: #f1f1f2;
$input-add-slot: #3f68d4;
$input-mob-struct-blue: #0078fa;
$input-email-temp-bg: #f4f4f4;
$input-email-temp-thanks: #ffec00;
$input-image-upload-border: $grey-alpha-30;
$input-pj-draggable-border: #d3d3d3;
$input-disabled: #e5e5e5;

// Footer
$footer-bg: $white;
$footer-links: #177cff;
$footer-copy: #828182;

// Unsorted
$background: #1562bc;
$address-color: #626262;
$location-list-header: #4986cc;
$location-list-bg-color: $white;
$locations-title: #0075cf;
$location-selected: #f1f7ff;
$button: #00d9a3;
$button-hover: #09c696;
$link: $white;
$link-hover: #cfcfcf;
$headings: $white;
$placeholder: #abaaaa;
$percentage-label: #00d9a3;
$tabs-bg: #f0f0f0;
$tab-titles: #4a4a4a;
$tab-titles-shadow: $black;
$input-focus: #fffcd5;
$dropdown-active: #eee;
$remove-field-btn: $base-color;
$remove-field-btn-bg: $white;

$image-upload-bg: $base-color;
$image-upload-bg-hover: #4986cc;
$image-item-hover: $base-color;
$publisher-disconnect-link: #f00;
$publisher-view-link: #56c224;

$fb-tutorial-indicators: #acacac;
$fb-tutorial-active-indicator: $base-color;
$fb-tutorial-nav: $base-color;
$fb-tutorial-description: #4a4a4a;
$modal-header-bg: #4986cc;
$disabled-action: #acacac;
$tutorial-link-color: #0078fa;

// new home page colors
$hp-light-green: #21d882;
$hp-very-light-green: #d3f7e6;

// EPJ selector
$loc-selector-arrow-color: #7a8fa6;

// Warning banner
$warning-banner-bg: #ffb527;
$warning-banner-text-color: $black-alpha-80;

// Dialog
$dialog-title-color: $black;
$dialog-text-color: #707070;
$dialog-colored-header-bg: #e5e5e580;
$dialog-step-line-color:#959BA6;

// Quotation
$quotation-border-color: #d2d2dc;
$quotation-source: #898989;
$quotation-unread-color: $main-color;
$quotation-time-horizon-bg: #2cd580;
$quotation-treated-color: $main-color;
$quotation-no-data-description: #7a8fa6;
$quotation-settings-activated-color: #19d77d;
$quotation-settings-deactivated-color: #979ca8;

// Appointment
$appointment-border-color: #d2d2dc;
$appointment-source: #898989;
$appointment-unread-color: $main-color;
$appointment-time-horizon-bg: #2cd580;
$appointment-treated-color: $main-color;
$appointment-no-data-description: #7a8fa6;
$appointment-settings-activated-color: #19d77d;
$appointment-settings-deactivated-color: #979ca8;

// Dialog
$dialog-title-color: $black;
$dialog-text-color: #707070;
$dialog-colored-header-bg: #e5e5e580;

// Form
$slm-input-bg: #fafafa;
$slm-input-color: #4c4c4c;
$slm-label-color: #828182;

//Order
$order-settings-activated-color: #19d77d;
$order-settings-deactivated-color: #979ca8;

// My Client Page colors
// ! Deprecated color names, do not use
// ! Use the colors bellow
$my-client-table-checkbox-border-color: #d0d3d9;
$my-client-table-checkbox-checked-color: #205ed9;
$my-client-table-header-bg: #fafafa;
$my-client-table-header-color: #00000080;
$my-client-table-desc-color: $black-color;
$my-client-table-border-color: #dcdfe6;
$my-client-contact-company-title-color: #828282;
$my-client-contact-email-phone-color: #0078fa;
$my-client-contact-prospect-color: #501fff;
$my-client-contact-client-color: #4b705f;
$my-client-contact-premium-border-color: #dfc99b;
$my-client-contact-border: #d0d3d9;
$my-client-status-inactive-color: #f03d3d;
$my-client-status-vip-color: #7b5608;
$my-client-status-client-color: #19d77d;
$my-client-status-prospect-color: #501fff;
$my-client-button-color: #007bff;
$my-client-button-bg: #0078fa;
$my-client-button-disable-bg: #fafafb;
$my-client-button-cancel-bg-hover: #f1f1f2;
$my-client-button-cancel-bg-activate: #C4C5C7;
$my-client-dialog-title-color: #212529;
$my-client-dialog-cancel-color: #828282;
$my-client-contact-more-tags-bg: #f0f0f5;
$my-client-box-shadow-color: #00000029;
$my-client-tag-hover-color: #ededed;
$my-client-blue-color: #00d5ff;
$my-client-main-blue-color: #006eff;
$my-client-detail-box-shadow-color: #0000001a;
$my-client-detail-blue-color: #0a68f1;
$my-client-detail-sub-title-color: #73767c;
$my-client-detail-confirm-color: #00872d;
$my-client-detail-cancel-color: #e03030;
$my-client-detail-hover-color: #fbfbfd;
$my-client-detail-placeholder-color: #b2b2b9;
$my-client-detail-start-color: #00afff;
$my-client-detail-end-color: #006eff;
$my-client-slide-toggle-bg: #eeeef3;
$my-client-input-border: #e9ebf0;
$my-client-collapsed-color: #4b1aff;
$my-client-loading-green: #19d77d;
$my-client-loading-blue: #501eff;
$my-client-odd-bg: #f9f9fb;
$text-notice-bg: #f5f5fa;
$my-client-checkbox-shadow: #0000001a;
$my-client-progress-buffer-bg: #cde9fe;
$my-client-progress-fill-bg: #0a68f1;
$my-client-grey: #d2d2dc;
$my-client-grey-ligth: #fbfbfd;
$my-client-text-light: #73767c;
$my-client-custom-fields-box-shadow-color: rgba(2, 0, 0, 0.16);
// My clients module colors
$my-client-white: #fff;

$my-client-grey-lightest: #e4ebf8;
$my-client-grey-lighter: #fbfbfd;
$my-client-grey-light: #f5f5fa;
$my-client-grey: #d2d2dc;
$my-client-grey-dark: #6e7075;
$my-client-grey-darker: #73767c;
$my-client-black: #000;

$my-client-blue-transparent: #eaf1fd;
$my-client-blue-lighter: #cde9fe;
$my-client-blue-light: #6ab1fa;
$my-client-blue: #0a68f1;
$my-client-blue-dark: #053bad;
$my-client-blue-darker: #011d73;
$my-client-blue-sky: #186ced;
$my-client-blue-sky-light: #6ab3f8;
$my-client-blue-10: #1168f1;

$my-client-indigo-lighter: #dfd1ff;
$my-client-indigo-light: #9975ff;
$my-client-indigo: #4b1aff;
$my-client-indigo-dark: #2a0db7;
$my-client-indigo-darker: #13047a;

$my-client-fushia-lighter: #fdcaf7;
$my-client-fushia-light: #f262f7;
$my-client-fushia: #bb00e5;
$my-client-fushia-dark: #6d00a4;
$my-client-fushia-darker: #38006d;

$my-client-purple-lighter: #eadbfe;
$my-client-purple-light: #bb95fe;
$my-client-purple: #834ffe;
$my-client-purple-dark: #4927b6;
$my-client-purple-darker: #210f79;

$my-client-yellow-1: #ffd982;

$my-client-yellow-lighter: #fff6d3;
$my-client-yellow-light: #ffdb7d;
$my-client-yellow: #ffb527;
$my-client-yellow-dark: #b77313;
$my-client-yellow-darker: #7a4207;

$my-client-spring-green-lighter: #cffed5;
$my-client-spring-green-light: #6ff99c;
$my-client-spring-green: #12ee86;
$my-client-spring-green-dark: #09ab7f;
$my-client-spring-green-darker: #03726a;

$my-client-green-lighter: #d8f9c7;
$my-client-green-light: #6edb57;
$my-client-green: #008a00;
$my-client-green-dark: #006311;
$my-client-green-darker: #004216;

$my-client-cyan-green: #21d882;
$my-client-cyan-green-dark: #73d95d;

$my-client-orange-lighter: #ffe9d3;
$my-client-orange-light: #ffa97c;
$my-client-orange: #ff5126;
$my-client-orange-dark: #db331b;
$my-client-orange-darker: #7a0712;
$my-client-salmon: #f58e7c;

$my-client-red-lighter: #fde1d2;
$my-client-red-light: #f68f7a;
$my-client-red: #e32424;
$my-client-red-dark: #a3122b;
$my-client-red-darker: #6c062a;

$my-client-azure-lighter: #f9fdfd;
$my-client-azure-light: #eaf5f7;
$my-client-azure: #d2e0e6;
$my-client-azure-dark: #698ba5;
$my-client-azure-darker: #28466e;

// my client custom fields
$custom-fields-hover-bg: rgba(205, 233, 254, 0.1);

//Activation feature
$pending-color: #ffaf14;

$solocal-blue: #0078fa;

$input-select-grey-bg: #ebebeb;

// Carousel
$carousel-arrow-color: #797a7c;

// my client custom fields
$custom-fields-hover-bg: rgba(205, 233, 254, 0.1);

// font weights
$font-medium: 500;
$font-regular: 400;
