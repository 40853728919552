/* poppins-regular - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('./poppins-v20-latin_latin-ext/poppins-v20-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
  src: url('./poppins-v20-latin_latin-ext/poppins-v20-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./poppins-v20-latin_latin-ext/poppins-v20-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./poppins-v20-latin_latin-ext/poppins-v20-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */
  url('./poppins-v20-latin_latin-ext/poppins-v20-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./poppins-v20-latin_latin-ext/poppins-v20-latin_latin-ext-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-500 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('./poppins-v20-latin_latin-ext/poppins-v20-latin_latin-ext-500.eot'); /* IE9 Compat Modes */
  src: url('./poppins-v20-latin_latin-ext/poppins-v20-latin_latin-ext-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./poppins-v20-latin_latin-ext/poppins-v20-latin_latin-ext-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('./poppins-v20-latin_latin-ext/poppins-v20-latin_latin-ext-500.woff') format('woff'), /* Modern Browsers */
  url('./poppins-v20-latin_latin-ext/poppins-v20-latin_latin-ext-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./poppins-v20-latin_latin-ext/poppins-v20-latin_latin-ext-500.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-600 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('./poppins-v20-latin_latin-ext/poppins-v20-latin_latin-ext-600.eot'); /* IE9 Compat Modes */
  src: url('./poppins-v20-latin_latin-ext/poppins-v20-latin_latin-ext-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./poppins-v20-latin_latin-ext/poppins-v20-latin_latin-ext-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('./poppins-v20-latin_latin-ext/poppins-v20-latin_latin-ext-600.woff') format('woff'), /* Modern Browsers */
  url('./poppins-v20-latin_latin-ext/poppins-v20-latin_latin-ext-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./poppins-v20-latin_latin-ext/poppins-v20-latin_latin-ext-600.svg#Poppins') format('svg'); /* Legacy iOS */
}
