/* Material Design Lite */

@import "../global/variables-bootstrap";

// Variables and mixins
@import "src/variables";
@import "src/mixins";

// Resets and dependencies
@import "src/typography/typography";

// Components
@import "src/badge/badge";
@import "src/card/card";
@import "src/data-table/data-table";
@import "src/shadow/shadow";

// Components
@import "custom";