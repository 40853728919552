slm-activate-click-and-collect {
  .mat-mdc-slide-toggle {
    height: unset;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
  .mat-slide-toggle-thumb {
    width: 1.1rem !important;
    height: 1.1rem !important;
    transform: translate(50%, 50%);
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
  .mat-slide-toggle-bar {
    background-color: #0078fa;
    border-radius: 1.407rem !important;
    height: 1.5rem !important;
    width: 2.875rem !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
  .mat-slide-toggle-thumb-container {
    top: -0.33rem !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
  .mat-mdc-slide-toggle .mat-slide-toggle-bar {
    background-color: #d2d2dc;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
  .mat-slide-toggle-label .mat-slide-toggle-content {
    white-space: normal !important;
  }

  /* position to left and right of the container */
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
  .mat-mdc-slide-toggle .mat-slide-toggle-thumb-container {
    left: -0.33rem;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
  .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    left: 0;
  }

  /* colors of the bar and the thumb */
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
  .mat-mdc-slide-toggle.mat-checked.mat-disabled .mat-slide-toggle-bar {
    background-color: #4779af;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
  .mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: #0078fa;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
  .mat-mdc-slide-toggle.mat-checked:not(.mat-disabled)
  .mat-slide-toggle-thumb {
    background-color: #fff;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
  .mat-mdc-slide-toggle .mat-slide-toggle-ripple {
    display: none;
  }
}
