@import 'variables';
@import 'mixins';
@import './assets/styles/functions';

.slm-dialog {
    .close-button {
        position: absolute;
        right: 0.75rem;
        top: 0.75rem;
    }

    .slm-dialog-colored-header {
        background: $dialog-colored-header-bg;
        height: 5rem;
        margin: -24px -3.25rem 24px;
        border-radius: 1.25rem 1.25rem 0 0;

        @include custom-width-540 {
            margin: -24px -24px 12px;
            height: 4rem;
        }
    }

    .slm-dialog-overflow-logo {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
        width: 40%;
        max-width: 10rem;

        img {
            width: 100%;
        }
    }

    .mat-mdc-dialog-container .mdc-dialog__surface {
        position: relative;
        border-radius: 1.25rem;
        padding-left: 3.25rem;
        padding-right: 3.25rem;
        width: 34.375rem;
        overflow: visible;
        margin-top: 5vh;
        max-height: 80vh !important;

        @include media(sm) {
            width: auto;
            max-width: 34.375rem;
        }

        @include custom-width-540 {
            padding: 24px; // Reset to material design default
        }
    }

    .mat-mdc-dialog-title {
        color: $dialog-title-color;
        padding: 0 4rem;
        @include font-size(1.3);
        font-weight: bold;

        @include custom-width-540 {
            padding: 0 2rem;
            @include font-size(1.125);
            line-height: 1.5;
        }
    }

    .mat-mdc-dialog-content {
        color: $dialog-text-color;
        max-height: 65vh;
        overflow: auto;
        @include font-size(0.9375);

        p {
            @include font-size(0.9375);
        }

        @include custom-width-540 {
            @include font-size(0.85);
            line-height: 1.5;
            p {
                @include font-size(0.85);
                line-height: 1.5;
            }
        }
    }

    .mat-mdc-dialog-actions {
        flex-direction: row;
        padding-left: 3rem;
        padding-bottom: 0;
        margin-bottom: 0;

        &[align='end'] {
            justify-content: flex-end;
        }

        button {
            border-radius: 100px;
            font-weight: normal;
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
        }

        .mat-mdc-unelevated-button {
            @include font-size(0.9375);
            @include wpm-gradient-reverse;
        }

        button + button {
            margin-left: 0;
            margin-top: 2rem;
        }

        @include custom-width-540 {
            padding: 0;

            .mat-mdc-button {
                @include font-size(0.85);
            }
        }
    }

    &-content-container {
        // dialogMaxHeight - headerHeight - (dialogPadding + headerMarginBottom)
        max-height: calc(80vh - 6.25rem - 48px);
        display: flex;
        flex-direction: column;
    }

    &.wide {
        .mat-mdc-dialog-container .mdc-dialog__surface {
            width: 70vw;
            max-width: 60rem;

            @include media(sm) {
                width: 90vw;
            }
        }
    }

    &--full-screen {
        min-width: 100%;
        min-height: 100vh;
    }

    &--full-screen-no-padding {
        min-width: 100%;

        .mat-mdc-dialog-container .mdc-dialog__surface {
            padding: 0;
        }
    }
}

.slm-dialog-payment {
    width: 550px;
    @include media(sm) {
        width: 90vw;
    }

    .mat-mdc-dialog-container .mdc-dialog__surface {
        width: unset;
        max-height: unset !important;
        padding: 0;
    }

    &.payment-ineligible .mat-mdc-dialog-container .mdc-dialog__surface .center {
        padding-bottom: 1.5rem;

        .slm-dialog-colored-header {
            margin-bottom: 1.5rem !important;
        }
    }

    .close-button {
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        .mat-button-focus-overlay {
            background: none !important;
        }
    }

    .slm-dialog-colored-header {
        margin: 0 0 1.5rem 0;

        @include custom-width-540 {
            margin-bottom: 0.5rem;
        }
    }

    .mat-mdc-dialog-title {
        font-size: 1.7rem;
        padding: 0;
        margin-bottom: 1.5rem;
        @include custom-width-540 {
            font-size: 1.57rem;
            margin-bottom: 0.5rem;
        }
    }

    .mat-mdc-dialog-content {
        border: 1px solid #f5f5fa;
        text-align: left;
        padding: 1rem;
        color: #000;
        margin: 0 3rem;

        @include custom-width-540 {
            margin: 0;
        }

        .invoice {
            display: flex;
            font-size: 1.29rem;
            font-weight: 700;
            justify-content: space-between;
            @include media(sm) {
                font-size: 1.15rem;
            }
        }

        .order {
            font-size: 1.15rem;
            margin-bottom: 0;
            @include media(sm) {
                font-size: 1rem;
            }
        }
    }

    .mat-mdc-dialog-actions {
        justify-content: flex-end;
        gap: 1.5rem;
        margin: 1rem 3rem;

        @include custom-width-540 {
            margin: 1rem;
        }

        solocal-button {
            font-size: 1.15rem;
        }
    }

    .information {
        margin: 2.5rem 4rem 0 4rem;
        color: #000;
        text-align: left;

        @include custom-width-540 {
            margin: 0.5rem 1rem 0.5rem 1.5rem;
        }

        &.payment-ineligilble {
            margin-top: 1.5rem;
        }

        p {
            font-size: 1rem !important;
            margin-bottom: 0.8rem;
            line-height: unset;
            @include media(sm) {
                font-size: 0.93rem !important;
            }
        }

        ul {
            padding-left: 1.2rem;
            list-style: none;

            li {
                line-height: 1.3;
                margin-bottom: 0.8rem;
                @include media(sm) {
                    font-size: 0.93rem;
                }
            }

            li::before {
                content: '●';
                color: $primary-blue;
                display: inline-block;
                width: 1.5rem;
                margin-left: -1.5rem;
            }

            .nowrap {
                white-space: nowrap;
            }
        }
    }

    .slm-dialog-colored-footer {
        background: $dialog-colored-header-bg;
        height: 8rem;
        border-radius: 0 0 1.25rem 1.25rem;
        @include media(sm) {
            height: 6rem;
        }

        p {
            padding-top: 1.5rem;
            font-size: 0.93rem;
            margin-bottom: 0.5rem;

            img {
                width: 20px;
                height: 20px;
            }

            @include media(sm) {
                padding-top: 0.5rem;
            }
        }

        .logos {
            img:nth-child(2) {
                padding: 0 1rem;
            }

            @include media(sm) {
                img {
                    height: 25px;
                }
            }

            .verifone-logo {
                width: 6rem;
            }
        }
    }
}

.slm-payment-redirection {
    width: 550px;
    @include media(sm) {
        width: 90vw;
    }

    .mat-mdc-dialog-container .mdc-dialog__surface {
        background: #f5f5fa;
        margin-top: 0;
    }

    .slm-dialog-white-header {
        background: white;
        height: 5rem;
        margin: -24px -3.25rem 24px;
        border-radius: 1.25rem 1.25rem 0 0;

        img {
            width: 8rem;
            margin-top: 1.1rem;
        }

        @include custom-width-540 {
            margin: 0;
        }
    }

    .mat-mdc-dialog-content {
        margin: 2rem;
        padding: 0;

        .loading-banner {
            width: 5rem;
            margin: 2.5rem auto 1rem;
            @include media(sm) {
                margin: 1rem auto;
            }
        }

        p {
            margin-bottom: 6rem;
            color: black;
            font-size: 1rem;
            font-weight: 600;
            @include media(sm) {
                margin-bottom: 1rem;
            }
        }
    }
}

.slm-payment-proceed {
    width: 550px;
    height: 450px;

    @include custom-width-540 {
        width: 95vw;
        height: 510px;
    }

    .mat-mdc-dialog-container .mdc-dialog__surface {
        width: 550px;
        padding: 0;
        margin-top: 0;
        background: #f6f6f6;

        @include custom-width-540 {
            width: 95vw;
            height: 510px;
        }

        .center {
            height: 100%;
        }
    }

    &.soms-payment {
        height: 620px;
        /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
        mat-dialog-container {
            background: white;
        }
    }
}

.slm-dialog-bank-details {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        border-radius: 1.25rem;
        padding: 0;
    }
}

.slm-dialog-payment-success,
.slm-dialog-payment-failure,
.slm-dialog-payment-cancel {
    width: 550px;
    @include media(sm) {
        width: 90vw;
    }

    .close-button {
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        .mat-button-focus-overlay {
            background: none !important;
        }
    }

    .mat-mdc-dialog-container .mdc-dialog__surface {
        padding: 0;
        width: unset;
    }

    .slm-dialog-colored-header {
        margin: 0;
    }

    .mat-mdc-dialog-title {
        font-size: 1.7rem;
        margin: 2rem 3.5rem 1.5rem 3.5rem;

        @include media(sm) {
            font-size: 1.57rem;
        }

        @include custom-width-540 {
            margin: 1.5rem 0;
        }
    }

    .mat-mdc-dialog-content {
        text-align: left;
        padding: 0 4rem;
        color: #000;
        margin: 0;

        @include custom-width-540 {
            padding: 0 1rem;
        }

        p {
            line-height: 1.4;
            font-size: 1rem;
            margin: 0;

            i {
                font-style: normal;
                color: $primary-blue;
            }
        }
    }

    .mat-mdc-dialog-actions {
        justify-content: center;
        gap: 1.5rem;
        margin: 1.5rem;
        padding: 0;

        @include custom-width-540 {
            margin-top: 1rem;
        }

        solocal-button {
            font-size: 1.15rem;
        }
    }
}

.error-dialog {
    width: 550px;
    @include media(sm) {
        width: 90vw;
    }

    .mat-mdc-dialog-container .mdc-dialog__surface {
        margin-top: 0;

        .close-button {
            /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
            .mat-button-focus-overlay {
                background: none !important;
            }
        }

        .mat-mdc-dialog-content {
            color: black;

            .message {
                margin: 1rem 0 2rem;
            }
        }
    }
}

.webcallback-dialog {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        padding: 0;
    }
}

.my-client-dialog {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        overflow: visible;
        position: relative;
        border-radius: rem(20, 16);
        padding-top: 0;

        @include media(sm) {
            padding: 0;
            border-radius: rem(10, 16) !important;
        }

        &__container-wrapper {
            @include media(sm) {
                padding: 0 rem(16);
            }
        }

        .mat-mdc-dialog-title {
            font-size: rem(24, 16);
            padding-top: rem(34, 16);
            padding-bottom: rem(20, 16);
            margin-bottom: 0;
            font-weight: bold;
            text-align: center;
            position: relative;

            @include media(sm) {
                position: relative;
                padding: rem(20);
                text-align: left;
            }

            &__action {
                display: none;

                @include media(sm) {
                    width: rem(40);
                    height: rem(40);
                    position: fixed;
                    right: rem(20);
                    margin-top: rem(-20);
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    color: $my-client-blue;
                    font-weight: 500;
                    font-size: rem(24);
                }
            }
        }

        .mat-mdc-dialog-content {
            padding: rem(15, 16) rem(100, 16);

            @include media(sm) {
                padding: rem(20, 16);
            }
        }

        .mat-mdc-dialog-actions {
            padding: rem(20, 16) 0 0;
            margin: 0 rem(16, 16);

            .sticky-actions,
            .content-actions {
                width: 100%;
                display: flex;

                & > *:not(:last-child) {
                    margin-right: rem(20, 16);
                }

                &.vertical-actions {
                    @include media(sm) {
                        flex-direction: column-reverse;
                        & > * {
                            margin-bottom: rem(20, 16);
                        }

                        & > *:not(:last-child) {
                            margin-right: 0;
                        }
                    }
                }

                &.center-action {
                    justify-content: center;
                }
            }

            .sticky-actions {
                padding: 0;
                justify-content: flex-end;
                align-items: center;

                @include media(sm) {
                    justify-content: center;
                    margin-bottom: rem(32);
                }
            }

            .content-actions {
                align-items: center;
                justify-content: center;
            }
        }
    }

    .mat-mdc-dialog-container__close {
        position: absolute;
        color: $my-client-black;
        cursor: pointer;
        top: 0;
        right: -25px;
        @include media(sm) {
            top: -5px;
            right: -6px;
        }

        &.close-icon {
            top: rem(20, 16);
            right: 0;

            @include media(sm) {
                top: rem(5);
                right: rem(5);
            }
        }

        &.back-icon {
            top: rem(16, 16);
            left: rem(16, 16);

            @include media(sm) {
                top: rem(16);
                left: rem(16);
            }
        }
    }
}

.my-client-dialog-merge-large {
    width: 950px !important;

    .mat-mdc-dialog-container .mdc-dialog__surface {
        overflow: visible;
        position: relative;
        border-radius: rem(20, 16);
        padding-top: 0;

        @include media(sm) {
            padding: 0 0;
            border-radius: rem(12, 16);
        }

        &__container-wrapper {
            @include media(sm) {
                padding: 0 rem(16);
            }
        }

        .mat-mdc-dialog-title {
            font-size: rem(24, 16);
            padding-top: rem(34, 16);
            padding-bottom: rem(20, 16);
            margin-bottom: 0;
            font-weight: bold;
            text-align: center;
            position: relative;

            @include media(sm) {
                position: relative;
                padding: rem(20);
                text-align: center;
                font-size: rem(22);
            }

            &__action {
                display: none;

                @include media(sm) {
                    width: rem(40);
                    height: rem(40);
                    position: fixed;
                    right: rem(20);
                    margin-top: rem(-20);
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    color: $my-client-blue;
                    font-weight: 500;
                    font-size: rem(24);
                }
            }
        }

        .mat-mdc-dialog-content {
            padding: rem(15, 16) rem(100, 16);

            @include media(sm) {
                padding: rem(20, 16) 0;
            }
        }

        .mat-dialog-actions {
            padding: 0;
            padding-top: rem(20, 16);
            margin: 0 rem(16, 16);
        }

        .mat-dialog-actions {
            .sticky-actions,
            .content-actions {
                width: 100%;
                display: flex;

                & > *:not(:last-child) {
                    margin-right: rem(20, 16);
                }

                &.vertical-actions {
                    @include custom-width-768 {
                        flex-direction: column-reverse;
                        & > * {
                            margin-bottom: rem(20, 16);
                        }

                        & > *:not(:last-child) {
                            margin-right: 0;
                        }
                    }
                }

                &.center-action {
                    justify-content: center;
                }
            }

            .sticky-actions {
                padding: 0;
                justify-content: flex-end;
                align-items: center;

                @include custom-width-768 {
                    justify-content: center;
                    margin-bottom: rem(32);
                }
            }

            .content-actions {
                align-items: center;
                justify-content: center;
            }
        }
    }

    .mat-dialog-container__close {
        position: absolute;
        color: $my-client-black;
        cursor: pointer;
        top: 0;
        right: -1.5625rem;
        @include media(sm) {
            top: -0.125rem;
            right: -0.3125rem;
        }
    }

    @include media(sm) {
        max-width: 95vw !important;
    }
}

.my-client-dialog--top {
    top: 9.6875rem;
}

.my-client-dialog--small {
    width: 26.875rem !important;

    .mat-mdc-dialog-container .mdc-dialog__surface {
        .mat-mdc-dialog-title {
            font-size: rem(20, 16);
            margin: rem(16, 16) 0 rem(16, 16) 0;
        }

        .mat-mdc-dialog-content {
            padding-left: rem(80, 16);
            padding-right: rem(80, 16);
        }
    }

    @include media(sm) {
        width: 90% !important;
    }
}

.my-client-dialog--medium {
    width: 588px !important;

    @include media(sm) {
        width: 90% !important;
    }
}

.my-client-dialog--large {
    width: 850px !important;

    @include media(sm) {
        width: 90% !important;
    }
}

.my-client-dialog--full-on-small-screen {
    @include media(sm) {
        min-width: 100%;
        height: 100%;

        .mat-mdc-dialog-container .mdc-dialog__surface {
            border-radius: unset;

            .mat-mdc-dialog-title {
                @include media(sm) {
                    height: auto;
                    text-align: left;
                    font-weight: bold;
                    line-height: rem(25);
                    padding: rem(16) rem(16) rem(16) rem(56);
                    box-shadow: 0 3px 6px $my-client-box-shadow-color;
                }
            }
        }

        .close-icon {
            display: none;
        }
    }
}

.my-client-dialog--low-padding {
    .mat-mdc-dialog-container .mdc-dialog__surface .mat-mdc-dialog-content {
        padding-left: rem(24, 16);
        padding-right: rem(24, 16);

        @include media(sm) {
            padding-bottom: 0;
        }
    }
}

.my-client-dialog--centered-actions {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        .mat-mdc-dialog-actions div[actions] {
            justify-content: center;
        }
    }
}

.slm-tooltip-modal {
    /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
    mat-dialog-container.mat-mdc-dialog-container .mdc-dialog__surface {
        padding: rem(10);
    }
}

.slm-dialog--full-screen-no-padding {
    min-width: 100%;
    height: 100%;
}

.slm-send-test-email-dialog {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        .mat-mdc-dialog-title {
            font-weight: bold;
            font-size: rem(26, 16);
        }

        label {
            font-size: rem(14, 16);
        }

        input {
            font-size: rem(16, 16);
        }

        .error-msg {
            font-size: rem(13, 16);
        }

        @include media(sm) {
            .mat-mdc-dialog-title {
                font-size: rem(20);
            }
        }
    }
}

.tooltip-template {
    padding-left: rem(12, 16);

    &__shortdesc {
        font-size: rem(13, 16);
        text-align: left;

        @include media(sm) {
            font-size: rem(13);
        }

        &.mb-15 {
            margin-bottom: rem(15, 16);
        }
    }

    &__img-warpper {
        margin-bottom: rem(10);

        img {
            width: 100%;
            height: auto;
        }
    }

    &__shortdesc,
    &__img-warpper {
        padding-right: rem(12);
    }

    &__icon-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    &__close-icon {
        width: rem(20);
        height: rem(20);
        position: relative;
        cursor: pointer;

        &:after,
        &:before {
            position: absolute;
            left: rem(10);
            content: ' ';
            height: rem(18);
            width: 1px;
            background-color: #333;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }

    &__first-time {
        color: $my-client-blue;
        text-align: center;
        font-size: rem(14, 16);
        text-decoration: underline;
        cursor: pointer;
    }
}

.dialog--confirm {
    width: 550px !important;

    @include media(sm) {
        width: calc(100% - 30px) !important;
        max-width: calc(100% - 30px) !important;
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version.*/
.mat-autocomplete-panel {
    &.contact-tag-autocomplete {
        margin-top: 2px;
        border-radius: 0;
        width: calc(100% + 4px);
        /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
        mat-option.mat-mdc-option {
            &.action {
                color: $my-client-blue;
                font-size: rem(14, 16);
                text-decoration: underline;
            }

            &.options {
                font-size: rem(14, 16);
                height: rem(38, 16);

                @include media(sm) {
                    font-size: rem(14);
                    height: rem(38);
                }

                &:first-child {
                    padding-top: rem(12, 16);

                    @include media(sm) {
                        padding-top: rem(12);
                    }
                }
            }
        }

        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        mat-checkbox.mat-mdc-checkbox {
            /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            &.mat-checkbox-checked {
                color: $my-client-blue;
                /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                .mat-checkbox-layout {
                    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                    .mat-checkbox-background {
                        background-color: $my-client-blue;
                        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                        .mat-checkbox-frame {
                            border-radius: 0;
                        }
                    }
                }
            }
        }
    }
}

.contact-actions-dialog {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        width: rem(550, 16);
        padding-left: 0;
        padding-right: 0;

        @include media(sm) {
            min-width: 100%;
        }

        .mat-mdc-dialog-container__close {
            top: 0;
            right: 0;
        }

        .mat-mdc-dialog-title {
            padding-top: rem(24, 16);
            padding-bottom: rem(24, 16);
            font-size: rem(26, 16);
            line-height: rem(36, 16);

            @include media(sm) {
                padding-top: rem(15);
                padding-bottom: rem(15);
                font-size: rem(22);
                line-height: rem(30);
                letter-spacing: -0.44pt;
            }
        }

        .mat-mdc-dialog-content {
            margin: 0;
            padding: 0 rem(24, 16) rem(34, 16);
            overflow-y: hidden;

            @include media(sm) {
                padding: rem(23) 0 0;
            }
        }

        .mat-mdc-dialog-actions {
            padding-top: 0;

            @include media(sm) {
                display: none;
            }
        }

        .button--link {
            text-transform: none;
            font-size: rem(16, 16);
            line-height: rem(20, 16);
        }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-label {
        color: $my-client-black;
    }

    @include media(sm) {
        .radio-button_label {
            margin-bottom: rem(10);
        }
        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        .mat-radio-label {
            margin-bottom: rem(15);
        }
    }
}

.merge-confirme-dialog {
    width: rem(550, 16);

    .mat-mdc-dialog-container .mdc-dialog__surface {
        overflow: visible;
        position: relative;
        border-radius: rem(20, 16);
        padding-top: 0;

        @include media(sm) {
            padding: 0;
        }

        &__container-wrapper {
            @include media(sm) {
                padding: 0 rem(16);
            }
        }

        .mat-mdc-dialog-title {
            font-size: rem(24, 16);
            padding-top: rem(34, 16);
            padding-bottom: rem(20, 16);
            margin-bottom: 0;
            font-weight: bold;
            text-align: center;
            position: relative;

            @include media(sm) {
                position: relative;
                padding: rem(20);
                text-align: center;
            }

            &__action {
                display: none;

                @include media(sm) {
                    width: rem(40);
                    height: rem(40);
                    position: fixed;
                    right: rem(20);
                    margin-top: rem(-20);
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    color: $my-client-blue;
                    font-weight: 500;
                    font-size: rem(24);
                }
            }
        }

        .mat-mdc-dialog-content {
            padding: rem(15, 16) rem(100, 16);

            @include media(sm) {
                padding: rem(20, 16);
            }
        }

        .mat-mdc-dialog-actions {
            padding: 0;
            margin-left: rem(32, 16) !important;
            margin-right: 0 !important;

            .sticky-actions,
            .content-actions {
                width: 100%;
                display: flex;

                & > *:not(:last-child) {
                    margin-right: rem(20, 16);
                }

                &.center-action {
                    justify-content: center;
                }
            }

            .sticky-actions {
                padding: 0;
                justify-content: flex-end;
                align-items: center;

                @include media(sm) {
                    justify-content: right;
                    margin-top: rem(15);
                    margin-bottom: rem(32);
                    margin-right: rem(15);
                }
            }

            .content-actions {
                align-items: center;
                justify-content: center;
            }

            @include media(sm) {
                margin-right: 0 !important;
                .button--primary {
                    padding: rem(25) rem(30) !important;
                }
            }
        }
    }

    .button {
        &__wrapper {
            &--text-container {
                text-transform: capitalize;
                font-size: 16px;
            }
        }
    }

    .mat-mdc-dialog-container__close {
        position: absolute;
        color: $my-client-black;
        cursor: pointer;
        top: 0;
        right: -25px;
        @include media(sm) {
            top: -2px;
            right: -5px;
        }
    }

    @include media(sm) {
        max-width: 95vw !important;
    }
}

.contact-actions-dialog-huge-export {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        width: rem(550, 16);
        padding-left: 0;
        padding-right: 0;

        @include media(sm) {
            min-width: 100%;
        }

        .mat-mdc-dialog-container__close {
            top: 0;
            right: 0;
        }

        .mat-mdc-dialog-title {
            padding-top: rem(24);
            padding-bottom: rem(24);
            font-size: rem(26, 16);
            line-height: rem(36, 16);

            @include media(sm) {
                padding-top: rem(24);
                padding-bottom: rem(24);
                font-size: rem(22);
                letter-spacing: -0.44pt;
                text-align: center;
            }
        }

        .mat-mdc-dialog-content {
            margin: 0;
            padding: 0 rem(24) rem(34, 16);
            overflow-y: hidden;

            @include media(sm) {
                padding: rem(23) 0 rem(15, 16);
            }
        }

        .mat-mdc-dialog-actions {
            padding-top: 0;
            padding-right: rem(10, 16);
            border-radius: rem(26, 16);

            .sticky-actions {
                margin-bottom: rem(10, 16);
            }

            @include media(sm) {
                padding-right: 0;
                .sticky-actions {
                    margin-bottom: rem(25, 16);
                    justify-content: flex-end;
                    padding-right: rem(8, 16);
                }
                .button--primary {
                    padding: rem(25, 16) rem(30, 16) !important;
                }
            }
        }

        .button--link {
            text-transform: none;
            font-size: rem(16, 16);
            line-height: rem(20, 16);
        }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-label {
        color: $my-client-black;
    }
}

.my-client-dialog-huge-export {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        overflow: visible;
        position: relative;
        border-radius: rem(20, 16);
        padding-top: 0;
        padding-bottom: 0;

        @include media(sm) {
            padding: 0;
            border-radius: 10px !important;
        }

        &__container-wrapper {
            @include media(sm) {
                padding-left: 5px;
            }
        }

        .mat-mdc-dialog-title {
            font-size: rem(24, 16);
            padding-top: rem(34, 16);
            padding-bottom: rem(20, 16);
            margin-bottom: 0;
            font-weight: bold;
            text-align: center;
            position: relative;

            @include media(sm) {
                position: relative;
                padding: rem(20);
                text-align: center;
            }

            &__action {
                display: none;

                @include media(sm) {
                    width: rem(40);
                    height: rem(40);
                    position: fixed;
                    right: rem(20);
                    margin-top: rem(-20);
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    color: $my-client-blue;
                    font-weight: 500;
                    font-size: rem(24);
                }
            }
        }

        .mat-mdc-dialog-content {
            padding: rem(15, 16) rem(25, 16);

            @include media(sm) {
                padding: 20px;
            }
        }

        .mat-mdc-dialog-actions {
            padding: rem(20, 16) 0 0;
            margin-bottom: rem(16, 16);
            margin-right: rem(25, 16);

            button {
                padding-right: rem(50, 16);
                padding-left: rem(50, 16);
            }

            @include media(sm) {
                padding-top: 0;
            }

            .sticky-actions,
            .content-actions {
                width: 100%;
                display: flex;

                & > *:not(:last-child) {
                    margin-right: rem(20, 16);
                }

                &.vertical-actions {
                    @include media(sm) {
                        flex-direction: column-reverse;
                        & > * {
                            margin-bottom: rem(20, 16);
                        }

                        & > *:not(:last-child) {
                            margin-right: 0;
                        }
                    }
                }

                &.center-action {
                    justify-content: center;
                }
            }

            .sticky-actions {
                padding: 0;
                justify-content: flex-end;
                align-items: center;

                @include media(sm) {
                    justify-content: right;
                    margin-bottom: 0;
                }
            }

            .content-actions {
                align-items: flex-start;
                justify-content: left;
            }
        }
    }

    .mat-mdc-dialog-container__close {
        position: absolute;
        color: $my-client-black;
        cursor: pointer;

        &.close-icon {
            top: rem(20, 16);
            right: 0;

            @include media(sm) {
                top: rem(5);
                right: rem(5);
            }
        }

        &.back-icon {
            top: rem(16, 16);
            left: rem(16, 16);

            @include media(sm) {
                top: rem(16);
                left: rem(16);
            }
        }
    }

    @include media(sm) {
        max-width: 95vw !important;
    }
}

.datetime-picker-modal {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        .mat-mdc-dialog-content {
            padding: 24px;
        }

        [mat-dialog-footer] {
            padding: 0 24px 24px;
        }
    }
}

.media-upload-dialog {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        padding: 10px;
    }
}
