// panels like select-panel are opened outside of the component so to override we need a global css file.
@import 'variables';
@import 'mixins';

// fix this loc-filter panel > select-search
.cdk-overlay-pane-select-search.cdk-overlay-pane-select-search-with-offset {
    margin-top: 0px !important;
}

// override the location-select panel
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.slm-mat-select-locations,
.slm-mat-select-multiple,
.slm-mat-filter-select,
.todo-list-loc-filter {
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-select-content {
        // background: greenyellow;
        .mat-mdc-option {
            display: -ms-flexbox;
            display: -webkit-flex;
            /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            .mat-primary .mat-pseudo-checkbox-checked,
            .mat-primary .mat-pseudo-checkbox-indeterminate,
            .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
            .mat-checkbox-checked.mat-accent .mat-checkbox-background {
                background: $input-check-checked;
                background-color: $input-check-checked;
            }

            .mat-pseudo-checkbox-checked {
                &::after {
                    top: 6px !important;
                    left: 4px !important;
                }
            }
            /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
            .mat-option-text {
                width: 94%;
                color: $input-option-text;
                .custom-option {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                }
            }

            &.toggle-all {
                // background: red;
                padding: 0 5px !important;
                @include font-size(1);

                .mat-pseudo-checkbox {
                    display: none !important;
                }

                span {
                    // padding-left: 0.8em;
                    // padding-top: 0.8em;
                    .mat-mdc-checkbox {
                        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                        .mat-checkbox-layout {
                            margin-top: 1em;
                            /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                            /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                            .mat-checkbox-inner-container {
                                margin-left: 0.8em;
                                /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                                .mat-checkbox-background {
                                    background-color: transparent;
                                }
                            }
                        }
                        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                        &.mat-checkbox-checked {
                            /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                            /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                            /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                            .mat-checkbox-layout {
                                /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                                /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                                .mat-checkbox-inner-container {
                                    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
                                    .mat-checkbox-background {
                                        background-color: $input-check-checked;
                                    }
                                }
                            }
                        }
                    }
                    .select-all-locations {
                        @include font-size(1.75);
                        cursor: pointer;
                        color: $input-select-all;
                    }
                    .select-all-text {
                        top: -0.3em;
                        position: relative;
                        @include font-size(1.2);
                        padding: 0;
                    }
                    .deselect-all-locations {
                        @include font-size(1.75);
                        cursor: pointer;
                        color: $input-deselect;
                    }
                }
            }
            /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
            /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
            &.mat-option-disabled {
                color: gray;
                cursor: not-allowed !important;
                mat-pseudo-checkbox {
                    &.mat-pseudo-checkbox-disabled {
                        cursor: not-allowed;
                        background-color: #bebebe;
                    }
                }
                /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
                .mat-option-text {
                    color: #bebebe !important;
                    .publisher {
                        filter: grayscale(100%);
                    }
                }
            }
        }
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.slm-mat-select-multiple,
.slm-mat-filter-select {
    /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
    .mat-option-text {
        color: black !important;
    }
    .mat-mdc-option-pseudo-checkbox {
        border-radius: 0;
    }
    .toggle-all {
        &.black {
            /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            .mat-option-text .mat-checkbox-label {
                color: black !important;
            }
        }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
    .mat-select-content .mat-mdc-option.mat-option-disabled {
        /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
        .mat-option-text {
            line-height: 0.9rem;
            color: black !important;
            .disable-text {
                font-size: 0.7rem;
                color: red;
            }
        }
        .mat-pseudo-checkbox-disabled {
            border: 1px solid #bebebe;
        }
    }
}
