// global variables
@import 'vendors/global/variables-bootstrap';
@import 'vendors/global/variables-extra';
@import 'vendors/material-design-lite/src/variables';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// MISSING VARIABLES
$theme_dark_text_color: rgba(255, 255, 255, 0.7);
$theme-dark-text-color: rgba(255, 255, 255, 0.7);

$theme_gray_box_bg: gray;
$theme-gray-box-bg: gray;

$theme-dark-box-bg: darkgray;
$theme_dark_box_bg: darkgray;

$font-family-sans-serif: 'Roboto', -apple-system, system-ui, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif !default;
$font-family-serif: Georgia, 'Times New Roman', Times, serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: 1.25rem !default;
$font-size-sm: 0.875rem !default;
$font-size-xs: 0.75rem !default;

$btn-padding-x-sm: 0.5rem !default;
$btn-padding-y-sm: 0.25rem !default;

$btn-padding-x-lg: 1.5rem !default;
$btn-padding-y-lg: 0.75rem !default;

$border-radius: 0.25rem !default;
$border-radius-lg: 0.3rem !default;
$border-radius-sm: 0.2rem !default;

$btn-border-radius: $border-radius !default;
$btn-border-radius-lg: $border-radius-lg !default;
$btn-border-radius-sm: $border-radius-sm !default;

$brand-primary: #0063d1;

$image_path: 'assets/images/' !default;
