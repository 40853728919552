@use "sass:math";
@import '../../../../../../assets/mixins';
@import 'variables';

@mixin placeholder($prefix: '&') {
    #{$prefix}::-webkit-input-placeholder {
        @content;
    }
    #{$prefix}:-moz-placeholder {
        @content;
    }
    #{$prefix}::-moz-placeholder {
        @content;
    }
    #{$prefix}:-ms-input-placeholder {
        @content;
    }
}

@mixin app-content {
    padding: 0 2rem !important;
    width: 100% !important;

    @include media(xs, sm) {
        padding: 0 0.5rem !important;
    }
    @include small-width() {
        padding: 0 1rem !important;
    }
}

@mixin max-height-with-nav($breadcrumb-deduct) {
    height: calc(
        100vh - #{$header-menu-height} - (#{$breadcrumbs-height} - #{$breadcrumb-deduct}) - #{$inner-nav-height} - 5px
    );
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin vcenter-absolute() {
    top: 50%;
    transform: translateY(-50%);
}

@mixin hcenter-absolute() {
    left: 50%;
    transform: translate(-50%, 0);
}

@mixin vbottom-absolute($offset: -50%) {
    top: 100%;
    transform: translateY($offset);
}

@mixin vtop-absolute($offset: -50%) {
    bottom: 100%;
    transform: translateY($offset);
}

@mixin xy-centered-absolute() {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin dotted-border(
    $color: #000,
    $orientation: horizontal,
    $position: unset,
    $spacing: 10px,
    $height: 1px,
    $width: 5
) {
    background-position: $position;
    @if $orientation == horizontal {
        background-image: linear-gradient(
                to right,
                $color math.div($height, $spacing) * $width * 100%,
                rgba(255, 255, 255, 0) 0%
        );
        background-size: $spacing $height;
        background-repeat: repeat-x;
    } @else {
        background-image: linear-gradient(
                $color math.div($width, $spacing) * $height * 100%,
                rgba(255, 255, 255, 0) 0%
        );
        background-size: $height $spacing;
        background-repeat: repeat-y;
    }
}

@mixin trigger-fade-in() {
    $time: 0.2s;
    -webkit-animation: fadein $time; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein $time; /* Firefox < 16 */
    -ms-animation: fadein $time; /* Internet Explorer */
    -o-animation: fadein $time; /* Opera < 12.1 */
    animation: fadein $time;
}

@mixin nav-fade-in() {
    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    /* Firefox < 16 */
    @-moz-keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    /* Internet Explorer */
    @-ms-keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    /* Opera < 12.1 */
    @-o-keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}

@mixin background-size($bgsize) {
    -webkit-background-size: $bgsize !important;
    -moz-background-size: $bgsize !important;
    -o-background-size: $bgsize !important;
    background-size: $bgsize !important;
}

@mixin wpm-gfb-gradient() {
    background: $main-grad-start !important;
    background: -moz-linear-gradient(left, $main-grad-start 40%, #00afff 100%) !important;
    background: -webkit-linear-gradient(left, $main-grad-start 40%, #00afff 100%) !important;
    background: linear-gradient(to right, $main-grad-start 40%, #00afff 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#006eff', endColorstr='#00afff', GradientType=1) !important;
}

@mixin wpm-gradient() {
    background: $main-grad-start !important;
    background: -moz-linear-gradient(left, $main-grad-start 40%, $main-grad-stop 100%) !important;
    background: -webkit-linear-gradient(left, $main-grad-start 40%, $main-grad-stop 100%) !important;
    background: linear-gradient(to right, $main-grad-start 40%, $main-grad-stop 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#006eff', endColorstr='#00afff', GradientType=1) !important;
}

@mixin wpm-gradient-reverse($startColor: $main-grad-start, $endColor: $main-grad-stop) {
    background: $endColor !important;
    background: -moz-linear-gradient(left, $endColor 0%, $startColor 100%) !important;
    background: -webkit-linear-gradient(left, $endColor 0%, $startColor 100%) !important;
    background: linear-gradient(to right, $endColor 0%, $startColor 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$startColor, endColorstr=$endColor, GradientType=1) !important;
}

@mixin overlay-map-gradient() {
    background: -webkit-linear-gradient(left, $white-alpha-00, $white-alpha-100);
    background: -moz-linear-gradient(left, $white-alpha-00, $white-alpha-100);
    background: -o-linear-gradient(to left, $white-alpha-00, $white-alpha-100);
    background: linear-gradient(to left, $white-alpha-00, $white-alpha-100);
}

@mixin media-box-gradient() {
    background: -moz-linear-gradient(top, $black-alpha-00 0%, $black-alpha-65 67%, $black-alpha-65 100%);
    background: -webkit-linear-gradient(top, $black-alpha-00 0%, $black-alpha-65 67%, $black-alpha-65 100%);
    background: linear-gradient(to bottom, $black-alpha-00 0%, $black-alpha-65 67%, $black-alpha-65 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
}

@mixin custom-linear-from-left($fromColor, $fromPer, $toColor, $toPer) {
    background: $fromColor !important;
    background: -moz-linear-gradient(left, $fromColor $fromPer, $toColor $toPer) !important;
    background: -webkit-linear-gradient(left, $fromColor $fromPer, $toColor $toPer) !important;
    background: linear-gradient(to right, $fromColor $fromPer, $toColor $toPer) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$fromColor, endColorstr=$toColor, GradientType=1) !important;
}

@mixin opacity($value) {
    $IEValue: $value * 100;
    opacity: $value;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=' + $IEValue + ')';
    filter: alpha(opacity=$IEValue);
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow: inset $top $left $blur $color;
        -moz-box-shadow: inset $top $left $blur $color;
        box-shadow: inset $top $left $blur $color;
    } @else {
        -webkit-box-shadow: $top $left $blur $color;
        -moz-box-shadow: $top $left $blur $color;
        box-shadow: $top $left $blur $color;
    }
}

@mixin drop-shadow {
    box-shadow: 1px 2px 5px 1px $black-alpha-11 !important;
}

@mixin light-drop-shadow {
    box-shadow: 0px 7px 6px -6px $black-alpha-20;
}

@mixin user-menu-drop-shadow {
    box-shadow: 0px 3px 10px 4px $black-alpha-20 !important;
}

@mixin disabled-drop-shadow {
    box-shadow: 0 3px 1px -2px $black-alpha-11, 0 2px 2px 0 $black-alpha-04, 0 1px 5px 0 $black-alpha-02;
}

@mixin app-box-shadow {
    -webkit-box-shadow: $box-shadow !important;
    -moz-box-shadow: $box-shadow !important;
    box-shadow: $box-shadow !important;
}

// REM => PX font size fall down
@mixin font-size($sizeValue: 1.6) {
    font-size: ($sizeValue * 16) + px;
    font-size: $sizeValue + rem;
}

// I like to move it, move it
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

@mixin animation-delay($str) {
    -webkit-animation-delay: #{$str};
    -moz-animation-delay: #{$str};
    -ms-animation-delay: #{$str};
    -o-animation-delay: #{$str};
    animation-delay: #{$str};
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

// generic transform
@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

// rotate
@mixin rotate($deg) {
    @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
    @include transform(scale($scale));
}

// translate
@mixin translate($x, $y) {
    @include transform(translate($x, $y));
}

// skew
@mixin skew($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}

// transform origin
@mixin transform-origin($origin) {
    -moz-transform-origin: $origin;
    -o-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
    transform-origin: $origin;
}

/* For browser dependent stuff */
@mixin browser($browsers: 'Mozilla') {
    @each $browser in $browsers {
        html[data-browser*='#{$browser}'] & {
            @content;
        }
    }
}

// Grid layout with MS prefixes
@mixin display-grid {
    display: -ms-grid;
    display: grid;
}

@mixin grid-child($col-start, $col-end, $row-start, $row-end) {
    -ms-grid-column: $col-start;
    -ms-grid-column-span: $col-end - $col-start;
    -ms-grid-row: $row-start;
    -ms-grid-row-span: $row-end - $row-start;
    grid-column: #{$col-start}/#{$col-end};
    grid-row: #{$row-start}/#{$row-end};
}

// Grid columns & rows with gaps for MS spec 1
@function box-gap($boxes, $gap) {
    $box: ();
    @for $i from 1 through length($boxes) {
        $box: append($box, nth($boxes, $i), space); // Adding Gap Between
        @if $gap > 0 {
            // Not last Loop
            @if $i != length($boxes) {
                $box: append($box, $gap, space);
            }
        }
    }
    @return $box;
}

@mixin grid-columns($gap, $columns) {
    grid-template-columns: $columns;
    -ms-grid-columns: $columns;
    @if $gap > 0 {
        grid-column-gap: $gap;
        -ms-grid-columns: box-gap($columns, $gap);
    }
}

@mixin grid-rows($gap, $rows) {
    grid-template-rows: $rows;
    -ms-grid-rows: $rows;
    @if $gap > 0 {
        grid-row-gap: $gap;
        -ms-grid-rows: box-gap($rows, $gap);
    }
}

@mixin wpm-common-button {
    @include wpm-gradient-reverse();
    font-size: 0.9375rem !important;
    min-width: 12.5rem !important;
    height: 3.25rem !important;
    font-weight: 500 !important;
    color: white !important;
}

@mixin wpm-button {
    border-radius: 0;
    @include font-size(0.875);
    text-align: center;
    min-width: 7.5rem;
    cursor: pointer;
    font-weight: 600;

    &[disabled] {
        cursor: not-allowed;
        filter: grayscale(100%);
    }
}

@mixin wpm-button-flat {
    @include wpm-button;
    @include wpm-gradient-reverse;
    padding: 0.3rem 2.5rem;
    border: none;
    color: white;
    transition: background-color 0.2s;

    // &:not([disabled]):hover {
    //     background: black !important;
    // }
}

@mixin wpm-button-stroked {
    @include wpm-button;
    color: #006eff;
    border: 2px solid #006eff;
    padding: calc(1rem - 1px) 1.5rem;
    background: none;
    transition: border-color 0.2s;
}

@mixin dpr1 {
    @media only screen and (-webkit-min-device-pixel-ratio: 1),
    only screen and (min--moz-device-pixel-ratio: 1),
    only screen and (min-device-pixel-ratio: 1),
    only screen and (min-resolution: 1dppx) {
        @content;
    }
}

@mixin dpr1_25 {
    @media only screen and (-webkit-min-device-pixel-ratio: 1.25),
    only screen and (min--moz-device-pixel-ratio: 1.25),
    only screen and (-o-min-device-pixel-ratio: 5/4),
    only screen and (min-device-pixel-ratio: 1.25),
    only screen and (min-resolution: 1.25dppx),
    only screen and (min-resolution: 120dpi) {
        @content;
    }
}

@mixin dpr1_3 {
    @media only screen and (-webkit-min-device-pixel-ratio: 1.3),
    only screen and (min--moz-device-pixel-ratio: 1.3),
    only screen and (-o-min-device-pixel-ratio: 4/3),
    only screen and (min-device-pixel-ratio: 1.3),
    only screen and (min-resolution: 1.3dppx),
    only screen and (min-resolution: 124.8dpi) {
        @content;
    }
}

@mixin dpr1_5 {
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (min--moz-device-pixel-ratio: 1.5),
    only screen and (-o-min-device-pixel-ratio: 3/2),
    only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 1.5dppx),
    only screen and (min-resolution: 144dpi) {
        @content;
    }
}

@mixin dpr2 {
    @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
        @content;
    }
}

@mixin disable-selection {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

@mixin outline($width: 4px) {
    outline: $width solid rgba($main-color, 0.5) !important;
}

@mixin outline-inset($width: 4px) {
    @include outline($width);
    outline-offset: -$width;
}

// My client module mixins
@mixin my-client-blue-gradient() {
    background: linear-gradient(rgba(5, 59, 173, 0.2) 0%, rgba(5, 59, 173, 0) 30%),
    linear-gradient(#0a68f1 0%, 93%, #6ab1fa);
}

@mixin my-client-indigo-gradient() {
    background: linear-gradient(rgba(42, 13, 183, 0.2) 0%, rgba(42, 13, 183, 0) 30%),
    linear-gradient(#4b1aff 0%, 93%, #9975ff);
}

@mixin my-client-fushia-gradient() {
    background: linear-gradient(rgba(109, 0, 164, 0.2) 0%, rgba(109, 0, 164, 0) 30%),
    linear-gradient(#bb00e5 0%, 93%, #f262f7);
}

@mixin my-client-purple-gradient() {
    background: linear-gradient(rgba(73, 39, 182, 0.2) 0%, rgba(73, 39, 182, 0) 30%),
    linear-gradient(#834ffe 0%, 93%, #bb95fe);
}

@mixin my-client-yellow-gradient() {
    background: linear-gradient(#ffb527, #ffdb7d, #fff6d3);
}

@mixin my-client-green-gradient() {
    background: linear-gradient(#12ee86, #6ff99c, #cffed5);
}

// @FE devs, please use it for convert PX to REM
@function -em($pxValue) {
    @return math.div($pxValue, 16) + rem;
}
