@import 'variables';
@import 'mixins';

// General CSS (old code...)

// COLORS
.purpleColor {
    color: $purple-color;
}

.redColor {
    color: $red;
}

.blueColor {
    color: $base-color !important;
}

.greyColor {
    color: $grey-color !important;
}

.blackColor {
    color: $black-color !important;
}

// BACKGROUND
.bg-white {
    background: $white;
}

.blueBg {
    @include wpm-gradient-reverse();
    @include border-radius(5px);
}

.blueBd {
    @include wpm-gradient-reverse();
    background: $white !important;
    border: 2px solid $main-grad-start !important;
    color: $main-grad-start !important;
}

// WIDTH
.width100 {
    width: 100%;
}

.width50 {
    width: 50%;
}

// HEIGHT
.height100 {
    height: 100%;
}

.height10em {
    height: 10em;
}

.height3em {
    height: 3em;
}

.height26 {
    height: 26em !important;
}

.heightCoverPic {
    height: auto;
}

// TRANSFORM
.upperCase {
    text-transform: uppercase;
}

.lowerCase {
    text-transform: lowercase;
}

.capitalCase {
    text-transform: capitalize;
}

// LINE HEIGHT
.lineHeight20 {
    line-height: 20px;
}

.lineHeight25 {
    line-height: 25px;
}

.lineHeight1_54 {
    line-height: 1.54;
}

.lineHeight1_33 {
    line-height: 1.33;
}

.lineHeight2 {
    line-height: 2;
}

.lineHeight2_33 {
    line-height: 2.33;
}

// PADDING
.noPadding {
    padding: 0em !important;
}

.padding05em {
    padding: 0.5em;
}

.padding025em {
    padding: 0.25em;
}

.padding30 {
    padding: 30px;
}

.padding20 {
    padding: 20px;
}

.padding15 {
    padding: 15px;
}

.padding4em {
    padding: 0 4em;
}

.paddingL1 {
    padding-left: 1em;
}

.paddingL1_75 {
    padding-left: 1.75em;
}

.paddingL1_5 {
    padding-left: 1.5em;
}

.paddingL0_75em {
    padding-left: 0.75em;
}

.paddingR1 {
    padding-right: 1em;
}

.paddingR2 {
    padding-right: 2em;
}

.paddingL2 {
    padding-left: 2em;
}

.paddingL4 {
    padding-left: 4em;
}

.paddingL15px {
    padding-left: 15px;
    padding-right: 15px;
}

.paddingL10 {
    padding-left: 10em;
}

.paddingB1 {
    padding-bottom: 1em;
}

.paddingB2 {
    padding-bottom: 2em;
}

.paddingB5 {
    padding-bottom: 5em;
}

.paddingT05 {
    padding-top: 0.5em;
}

.paddingT1 {
    padding-top: 1em;
}

.paddingT2 {
    padding-top: 2em;
}

.customPadding {
    padding: 0.5em 0em !important;
}

.customPadding2 {
    padding: 1em 1em 0 1em !important;
}

.paddingBottom {
    padding-bottom: 1em;
}

// MARGIN
.margin1em0 {
    margin: 1em 0;
}

.noMargin {
    margin: 0;
}

.margin1_5em {
    margin: 1.5em 1.5em;
}

.marginL20 {
    margin-left: 20px;
}

.marginL2em {
    margin-left: 2em;
}

.marginL0 {
    margin-left: 0em !important;
}

.marginR20 {
    margin-right: 20px !important;
}

.marginB4 {
    margin-bottom: 4em !important;
}

.marginB05em {
    margin-bottom: 0.5em !important;
}

.marginB1 {
    margin-bottom: 1em !important;
}

.marginTop {
    margin-top: 1em !important;
}

.marginTop2 {
    margin-top: 2em !important;
}

.marginTop3 {
    margin-top: 3em !important;
}

.marginTop-1 {
    margin-top: -1em !important;
}

.marginTop-2 {
    margin-top: -2em !important;
}

.marginTop-45 {
    margin-top: -4.5em;
}

.margin2rem {
    margin: 2rem;
}

.marginT2rem {
    margin-top: 2rem;
}

.marginB2rem {
    margin-bottom: 2rem;
}

.marginL3rem {
    margin-left: 3rem;
}

.marginR3rem {
    margin-right: 3rem;
}

.marginHomeBloc {
    margin: 4em 1em 0 1em;
}

.marginHomeBloc2 {
    margin: 0 0 1em 0;
}

// FONT SIZE

.font12 {
    @include font-size(0.75);
}

.font14 {
    @include font-size(0.875);
}

.font16 {
    @include font-size(1);
}

.fontSize40 {
    @include font-size(2.5);
}

.fontSize28 {
    @include font-size(1.75);
}

.fontSize24 {
    @include font-size(1.5);
}

.fontSize20 {
    @include font-size(1.25);
}

.fontSize18 {
    @include font-size(1.125);
}

.fontSize17 {
    @include font-size(1.0625);
}

.fontSize16 {
    @include font-size(1);
}

.fontSize15 {
    @include font-size(0.9375);
}

.fontSize14 {
    @include font-size(0.875);
}

.fontSize13 {
    @include font-size(0.8125);
}

.fontSize12 {
    @include font-size(0.75);
}

.fontSize11 {
    @include font-size(0.6875);
}

.fontSize175em {
    @include font-size(1.75);
}

// FONT WEIGHT
.fontWeight500 {
    font-weight: 500 !important;
}

.fontWeight600 {
    font-weight: 600 !important;
}

.fontWeightNormal {
    font-weight: normal !important;
}

// UTILS
.flex {
    display: flex;

    &-column {
        flex-direction: column;

        &-reverse {
            flex-direction: column-reverse;
        }
    }
}

.oneLine {
    white-space: nowrap !important;
}

.vAlignMiddle {
    vertical-align: middle;
}

.vAlignBottom {
    vertical-align: bottom;
}

.vAlignTextBottom {
    vertical-align: text-bottom;
}

.center {
    text-align: center;
}

.centerMargin {
    margin-left: auto;
    margin-right: auto;
}

.justify-content {
    &-start {
        justify-content: flex-start;
    }

    &-end {
        justify-content: flex-end;
    }

    &-baseline {
        justify-content: baseline;
    }

    &-center {
        justify-content: center;
    }

    &-around {
        justify-content: space-around;
    }

    &-between {
        justify-content: space-between;
    }

    &-evenly {
        justify-content: space-evenly;
    }
}

.align-items {
    &-start {
        align-items: flex-start;
    }

    &-end {
        align-items: flex-end;
    }

    &-baseline {
        align-items: baseline;
    }

    &-center {
        align-items: center;
    }
}

.inlineBlock {
    display: inline-block !important;
}

.call-to-action {
    text-align: left;
}

.block {
    display: block;
}

.ellipsisText {
    text-overflow: ellipsis;
}

.underline {
    text-decoration: underline;
}

.pull-left {
    float: left;
}
