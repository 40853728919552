$zindex_dropdown_menu: (
    $zindex-dropdown + 1
); // larger than zindex of header, sidebar, quickview

// for App Layouts
.app-sidebar {
    [md-button],
    [md-raised-button],
    [md-fab],
    [md-icon-button],
    [md-mini-fab] {
        font-weight: normal;
        min-width: inherit; // for collapsed sidenav, overrides default 88px
    }
}
.quickview-inner {
    /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
    .mat-mdc-list-item .mat-list-item-content {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        > p {
            flex: 1 1 auto;
            margin: 0;
        }
        .md-secondary {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            margin-right: 0;
            justify-content: flex-end;
        }
    }
}

body {
    .cdk-overlay-container {
        z-index: 1011;
    }
}
