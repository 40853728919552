@import 'functions';
@import 'variables';
@import 'mixins';

.page-title {
    @include font-size(2.25);
    font-weight: 600;
    line-height: 1.5;
}

.section-title {
    @include font-size(1.125);
    font-weight: 600;
    line-height: 1.5;
}
