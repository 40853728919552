@import 'variables';
@import 'mixins';

label.slm-label {
    color: $slm-label-color;
    @include font-size(0.75);
    display: block;
    margin-bottom: 0.25rem;
}

input.slm-input {
    border: 1px solid $grey-border;
    color: $slm-input-color;
    background: $slm-input-bg;
    @include font-size(0.9375);
    padding: 0.625rem;
    display: block;
    width: 100%;

    &.ng-invalid.ng-dirty {
        border-color: $invalid;
        caret-color: $invalid;
    }

    &-error {
        color: $invalid;
        @include font-size(0.75);
    }
}
